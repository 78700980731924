import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import toast, { Toaster } from "react-hot-toast";
import config from "../coreFIles/config";
import Cookies from 'js-cookie';
import moment from 'moment';
import Swal from 'sweetalert2';
import { getBranchesListAction, getDomainsListAction, getTopicListAction, getModuleDetailsAction,getVRassetListAction, getModuleVRassetsAction,createVRassetAction, getModulesTypesAction,approveVRassetAction,getVRAssetLatestVersionAction,getVRassetDetailsAction } from "../Action/action";
import { Link, useParams } from 'react-router-dom';
const loginData = (!Cookies.get('loginSuccessIXRAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessIXRAdmin'));


const Vrassets = () => {
	const { id } = useParams();
	const [BranchesList, setBranchesList] = useState([]);		
	const [branch_id, setbranch_id] = useState(null);		
	const [DomainsList, setDomainsList] = useState([]);	
	const [domain_id, setdomain_id] = useState(null);			
	const [TopicsList, setTopicsList] = useState([]);		
	const [FilterTopicsList, setFilterTopicsList] = useState([]);
	const [spindetailloader, setspindetailloader] = useState(0);
	const [VrassetsList, setVrassetsList] = useState([]);	  
	const [moduletypeList, setmoduletypeList] = useState([]);	  
	const [moduledata, setmoduledata] = useState({});	
	const [validationError, setvalidationError] = useState({});	
	const [modalopen, setModalopen] = useState(0);	
	const [hashfile, sethashfile] = useState(null);	
	const [jsonfile, setjsonfile] = useState(null);	
	const [textfile, settextfile] = useState(null);	
	const [bundle, setbundle] = useState([]);		
	const editorHeight = '400px'; 
	const [form, setForm] = useState({ 
		module_id: id,		
		vr_asset_base_url: '', 
		version: '', 
		module_type_id: '', 
		hash_file_url: '', 
		json_file_url: '', 
		text_file_url: '', 
		bundles_file_url: [], 
		uploaded_by_user_id: loginData?.id	
	 });	
	const [assetDetails, setassetDetails] = useState({ 		
		version: '', 
		module_type_id: '', 
		hash_file_url: '', 
		json_file_url: '', 
		text_file_url: '', 
		bundles_file_url: [], 
		uploaded_by:''
	 });	
  
   useEffect(() => {	   
	    getBranchesListAPI();       
		getDomainsListAPI();       
		getTopicListAPI();
		getModulesTypesAPI();
		getModuleDetailsAPI();        
		getVRAssetLatestVersionAPI();        
		getModuleVRassetsAPI();        
    }, [])
    
    const inputHandler = async (e) => {
		const { name, value } = e.target
		setForm((old) => {
			return { ...old, [name]: value }
		})
	}
	 const inputhashfileHandler = (e) => {
		 e.preventDefault()
        let image_as_base64 = URL.createObjectURL(e.target.files[0])
        let image_as_files = e.target.files[0];
        let imageType = e.target.files[0].type;
      //  setimage_file(image_as_files);        
		setForm((old) => {
			return { ...old, ['hash_file_url']: image_as_files }
		})
	};
	 const inputjsonfileHandler = (e) => {
		 e.preventDefault()
        let image_as_base64 = URL.createObjectURL(e.target.files[0])
        let image_as_files = e.target.files[0];
        let imageType = e.target.files[0].type;
       // setimage_file(image_as_files);        
		setForm((old) => {
			return { ...old, ['json_file_url']: image_as_files }
		})
	  };
	 const inputtextfileHandler = (e) => {
		 e.preventDefault()
        let image_as_base64 = URL.createObjectURL(e.target.files[0])
        let image_as_files = e.target.files[0];
        let imageType = e.target.files[0].type;
       // setimage_file(image_as_files);        
		setForm((old) => {
			return { ...old, ['text_file_url']: image_as_files }
		})
	  };
	 const inputbundleHandler = (e) => {
		 e.preventDefault()
         const files = Array.from(e.target.files);
         let newFiles = [];
         
		 Array.from(files).forEach((file) => {
			newFiles.push(file);
		 });
             
		setForm((old) => {
			return { ...old, ['bundles_file_url'] :newFiles }
		})
	  };

    const getModuleDetailsAPI = async () => {
		setspindetailloader(1);
        let res = await getModuleDetailsAction({ 'id': id });
        if (res.success) {
            setmoduledata(res.data)
            setFilterTopicsList(res.topics)
            setbranch_id(res.data.branch_id);	
			setdomain_id(res.data.domain_id);
			setspindetailloader(0);
			
			setForm((old) => {
				return { ...old, ['module_type_id'] :res.data.module_type_id }
			})
			
			
           }
    } 
    const getVRAssetLatestVersionAPI = async () => {
		let res = await getVRAssetLatestVersionAction({ 'module_id': id });
        if (res.success) {
          setForm((old) => {
			return { ...old, ['version']: res.data }
			})
           }
    } 
    const getModuleVRassetsAPI = async () => {
		setspindetailloader(1);
        let res = await getModuleVRassetsAction({ 'module_id': id });
        if (res.success) {
            setVrassetsList(res.data)          
			setspindetailloader(0);
           }
    } 
	const getModulesTypesAPI = async () => {
		let res = await getModulesTypesAction();
		if (res.success) {
			setmoduletypeList(res.data);	
		}
	}
	const getBranchesListAPI = async () => {
		let res = await getBranchesListAction();
		if (res.success) {
			setBranchesList(res.data);	
		}
	}
	const getDomainsListAPI = async () => {
		let res = await getDomainsListAction();
		if (res.success) {
			setDomainsList(res.data);	
		}
	}
	const getTopicListAPI = async () => {
		let res = await getTopicListAction();
		if (res.success) {
			setTopicsList(res.data);	
		}
	}
	const filterBranchHandler = async (e) => {
	 setbranch_id(e.target.value);
	 let searchBranch = e.target.value; 
	 if(searchBranch != null && domain_id !=null){
		 console.log(TopicsList);
		const result = TopicsList.filter(data => String(data.branch_id).includes(searchBranch) && String(data.domain_id).includes(domain_id)); 
		setFilterTopicsList(result); 		
	  } 
		
	}
	const filterDomainHandler = async (e) => {
		 setdomain_id(e.target.value); 		 
		 let searchDomain = e.target.value; 
		 if(searchDomain != null && branch_id !=null){
			  console.log(TopicsList);
			const result = TopicsList.filter(data => String(data.branch_id).includes(branch_id) && String(data.domain_id).includes(searchDomain)); 
			setFilterTopicsList(result); 		
		  }
	}
    function validate() {
		let versionError = "";
		let hash_file_urlError = "";
		let json_file_urlError = "";
		let text_file_urlError = "";
		let bundles_file_urlError = "";
		
				
		if (form.version === '') {
		  versionError = "Version is required."
		}
		if (form.hash_file_url === '') {
			hash_file_urlError = "Hash file is required."
		}
		if (form.json_file_url === '') {
			json_file_urlError = "Json file is required."
		}
		if (form.text_file_url === '') {
			text_file_urlError = "Text file is required."
		}
		if (form.bundles_file_url === '') {
			bundles_file_urlError = "Bundles file is required."
		}
		
				
		if (versionError || hash_file_urlError || json_file_urlError || text_file_urlError || bundles_file_urlError) {
			setvalidationError({
			  versionError, hash_file_urlError, json_file_urlError, text_file_urlError, bundles_file_urlError
			})
			return false
		} else {
			setvalidationError({
			  versionError, hash_file_urlError, json_file_urlError, text_file_urlError, bundles_file_urlError
			})
			return true
		}
	}
	
	const SubmitForm = async (e) => {
		e.preventDefault()
		const isValid = validate();
		if (!isValid) {

		}
		else {
			
			let res = await createVRassetAction(form);
			if (res.success) {
				console.log(res.msg);
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}vrassets/`+id;
				}, 1200);
			} else {
				toast.error(res.msg);
			}
		}
	}
	
	const handleAssetApproval = async (asset_id, is_approved) => {
        Swal.fire({
			html: `
				<div>
				  <h4>Warning</h4>
				  <p>${is_approved === 0 ? "You want to reject this Asset!" : "You want to approved this Asset!"}</p>
				  <div>
					<img src="${config.baseUrl}images/warning.png" alt="Warning Image" />
				  </div>
				</div>
			  `,           
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: is_approved == 0 ? 'Yes, reject it!' : 'Yes, approve it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await await approveVRassetAction({ 'id': asset_id, 'is_approved': is_approved });
                if (res.success) {
                    getModuleVRassetsAPI();
                    if(is_approved==0)
						toast.success("Asset rejected successfully!");
                    else
                     toast.success("Asset Aapproved successfully!"); 
                } else {
                    toast.error(res.msg); 
                }
            }
        })
    }
	const closebutton = async () => {
        setModalopen(0);
    }
    
   function afterOpenModal() {
        //subtitle.style.color = '#f00';
    }
    const viewAssetDetails  = async (item_id) => {
	    
	    let res = await getVRassetDetailsAction({ 'id': item_id });
        if (res.success) {
			setassetDetails(res.data);
			setModalopen(1);       
		} 
    }	
  return (

    <>
      <Sidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
         <Toaster />
        <Header />
         <div className="container-lg mb-4">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-0 ms-2">
              <li className="breadcrumb-item">
               <span><a className="homelink" href={`${config.baseUrl}users`} >Home</a></span>
              </li>
              <li className="breadcrumb-item">
               <span><a href={`${config.baseUrl}assets-list`} >Assets</a></span>
              </li>
              <li className="breadcrumb-item">
               <span><a href={`${config.baseUrl}editmodule/`+id} >{moduledata?.title}</a></span>
              </li>
              <li className="breadcrumb-item active"><span>VR Assets</span></li>
            </ol>
          </nav>
        </div>
        <div className="body flex-grow-1 px-3">
        <div className="container-lg">
          <div className="row align-items-end">
            
          <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header">
					<div className="row align-items-center">				
						<div className="col-md-6 text-start">
						 <span>VR Assets</span>
						</div>
						<div className="col-md-6 text-end">
							<a className="btn btn-primary editbtn" href={`${config.baseUrl}assets-list`} >Back</a>
						</div>
					</div>
					</div>
					
					<div className="card-body inner-body"> 
					<div className="row g-3 evnt-disable">
					 <div className="col-md-6">
						<label className="form-label" for="domain_id">Course<span className="req-star">*</span></label>
						<select className="form-control evnt-disable" aria-label="select example" id="domain_id" name="domain_id" value={domain_id}>
                              <option value="">Select Course</option>
								{
									DomainsList.map((item,index)=>{
										return(
											<option value={item.id}>{item.title}</option>
										)
									})
								}
                            </select>
					  </div>
						<div className="col-md-6">
						<label className="form-label" for="branch_id">Branch<span className="req-star">*</span></label>
						<select className="form-control" aria-label="select example" id="branch_id" name="branch_id" value={branch_id}>
                              <option value="">Select Branch</option>
								{
									BranchesList.map((item)=>{
										return(
											<option value={item.id}>{item.title}</option>
										)
									})
								}
                            </select>                           
					  </div>
					    <div className="col-md-6">
						<label className="form-label" for="topic_id">Topic<span className="req-star">*</span></label>
						<select className='form-control' aria-label="select example" id="topic_id" name="topic_id" value={moduledata?.topic_id} >
                              <option value="">Select Topic</option>
							{spindetailloader == '0' ?	
									FilterTopicsList.map((item,index)=>{
										return(
											<option value={item.id}>{item.title}</option>
										)
									})								
								:
								'<i className="fa fa-spinner fa-spin validat"></i>'
							}
                            </select>
					  </div>
					 
					  <div className="col-md-6">
						<label className="form-label" for="title">Title<span className="req-star">*</span></label>
						<input className='form-control' id="title" name="title" type="text" value={moduledata?.title} maxLength={30} />
					  </div>
					  
					  <div className="col-md-6">
						<label className="form-label" for="version">Version<span className="req-star">*</span></label>
						<input className='form-control' id="version" name="version" type="text" onChange={inputHandler} value={moduledata?.version} />
					  </div>
					  <div className="col-md-6">
						<label className="form-label" for="module_type_id">Module Type<span className="req-star">*</span></label>
						<select className='form-control' aria-label="select example" id="module_type_id" name="module_type_id" value={moduledata?.module_type_id}>
                              <option value="">Select Module Type</option>
								{
									moduletypeList.map((item)=>{
										return(
											<option value={item.id}>{item.title}</option>
										)
									})
								}
                            </select>   
                        </div>	
					  </div>	
					  </div>
					   <div className="col-md-12 mt-5 mb-3 ml-30">
					   <label className="form-label description-label" for="first_name">Upload Assets</label>					  
					   </div> 
					  <div className="card-body form-body"> 						 
						  <div className="row g-3">
							 
						 <div className="col-md-6 mb-4">
						<label className="form-label" for="hash_file_url">Hash File<span className="req-star">*</span></label>
						<input className={validationError.hash_file_urlError ? 'form-control is-invalid' : 'form-control'} id="hash_file_url" name="hash_file_url" placeholder="Enter hash file URL" type="file" onChange={inputhashfileHandler} />
						  <span className="validationErr">{validationError.hash_file_urlError}</span>						
					  </div>	
					   <div className="col-md-6 mb-4">
						<label className="form-label" for="json_file_url">Json File<span className="req-star">*</span></label>
						<input className={validationError.json_file_urlError ? 'form-control is-invalid' : 'form-control'} id="json_file_url" name="json_file_url" placeholder="Enter json file URL"type="file" onChange={inputjsonfileHandler} />
						 <span className="validationErr">{validationError.json_file_urlError}</span>
										
					  </div>	
					   <div className="col-md-6 mb-4">
						<label className="form-label" for="text_file_url">Text File<span className="req-star">*</span></label>
						<input className={validationError.text_file_urlError ? 'form-control is-invalid' : 'form-control'} id="text_file_url" name="text_file_url" placeholder="Enter text file URL" type="file" onChange={inputtextfileHandler}  />	
						<span className="validationErr">{validationError.text_file_urlError}</span>					
					  </div>
					  		
					   <div className="col-md-6 mb-4">
						<label className="form-label" for="bundles_file_url">Bundles File<span className="req-star">*</span></label>
						<input className={validationError.bundles_file_urlError ? 'form-control is-invalid' : 'form-control'} id="bundles_file_url" name="bundles_file_url" multiple placeholder="Enter bundles files URL" type="file" onChange={inputbundleHandler} />
						<span className="validationErr">{validationError.bundles_file_urlError}</span>						
					  </div>					 				
					  </div>	
					  </div>	
					  <div className="row align-items-center mb-4 mt-5">				
						 <div className="col-12 text-center">
							<button className="btn btn-primary" type="submit" onClick={SubmitForm} >Update</button>
						  </div>					
					</div>                
                <div className="card-body">
					<div className="col-md-12 text-start">
						 <span className="ml-20">VR Assets Updates Activities</span>
						</div>					
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead className="table-light fw-semibold">
                        <tr className="align-middle table-heading">
                           <th>Sr.</th>                         
                          <th>Module</th>
                          <th>Uploaded By</th>
                          <th>Uploaded On</th>
                          <th>Version</th>
                          <th>Approved by</th>
                          <th>Approved On</th>
                          <th>Status</th>                          
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                         { VrassetsList.length> 0 ?
							VrassetsList.map((item, index) => (
								<>
									<tr key={index}>
										<td>{index+1}</td>
										<td><a href="javascript:;" onClick={() => { viewAssetDetails(item.id) }}>{item.module_title}</a></td>
										<td>{item.uploadedby}</td>
										<td>{moment(item.created_on).format('DD/MM/YYYY')}</td>
										<td>{item.version}</td>
										<td>{item.approved_by_user_id !=0 ? item.approvedby:'-'}</td>
										<td>{item.approved_by_user_id !=0 ? moment(item.approved_on).format('DD/MM/YYYY') :'-'}</td>
										<td>{item.is_approved ==1 ? 'Approved':'Pending for approval' }</td>
										<td>										
											<button className={item.is_approved ==1 ? 'btn btn-danger deletebtn':'btn btn-success deletebtn' } type="submit" onClick={() => handleAssetApproval(item.id, item.is_approved === 1 ? 0 : 1)} >
											{item.is_approved ==1 ? 'Reject':'Approve' }
											</button>
										 	
																			  
										</td>
										
									</tr>
									</>
								)
							)
							:
							<>
									<tr>
										<td colspan="9">No record found</td>
									</tr>
							</>						 
						}
                      
                      </tbody>
                    </table>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>          
        </div>
      </div> 
      
      <div className={modalopen == '0' ? "modal fade cart-modal mc-popup" : "mc-popup modal fade cart-modal show"} role="dialog" style={{ background: '0% 0% / cover rgba(6, 6, 6, 0.32)', display: modalopen == '0' ? 'none' : 'block' }}  id="myModal" tabindex="-1" data-backdrop="false">
		  <div className="modal-dialog modal-dialog-centered modal-lg">
			<div className="modal-content">
			  <div className="modal-header">				
				<button className="btn-close" type="button" onClick={closebutton} aria-label="Close"></button>
			  </div>
			  <div className="modal-body">
				<div className="row mb-4">
				 <div className="table-responsive">
                    <table className="table mb-0">
                      <thead className="table-light fw-semibold">
                        <tr className="align-middle">                                            
                          <th>Version</th>
                          <th>Hash file URL</th>
                          <th>JSON file URL</th>
                          <th>Text file URL</th>                          
                          <th>Bundles file URL</th>                          
                          <th>Uploaded by</th>
                        </tr>
                      </thead>
                      <tbody>
                       <tr>							
							<td>{assetDetails.version}</td>
							<td>{assetDetails.hash_file_url}</td>
							<td>{assetDetails.json_file_url}</td>
							<td>{assetDetails.text_file_url}</td>
							<td>{assetDetails.bundles_file_url}</td>
							<td>{assetDetails.uploaded_by}</td>
						</tr>
                      </tbody>
                    </table>
                    
                  </div>
				</div>
			  </div>			 
			</div>
		  </div>
		</div>
         
      <Footer />
      </div>
    </>
  )

};
export default Vrassets;
