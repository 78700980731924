import React, { useEffect, useState } from 'react'
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Sidebar from '../directives/sidebar'

import { getRoleslistAction,getBranchesListAction,getCollegesListAction,getUserDetailsAction,UpdateUserProfileAction, UpdateUserPasswordAction } from '../Action/action';

const EditUser = () => {
	const { id } = useParams();	
	const [form, setForm] = useState({ user_id: '', branch_id: '', college_id: '', first_name: '', last_name: '', m_pin: '', year: '' });
	
	const [pwdform, setpwdform] = useState({ password: '', cpassword: '', user_id:id  });
	
	const [validationError, setvalidationError] = useState({});
	
	const [validError, setvalidError] = useState({});
	
	const [UserDetails, setUserDetails] = useState({});
	
	const [UserEmail, setUserEmail] 	= useState('');
	
	const [UserRole, setUserRole] 		= useState('');
	
	const [RolesList, setRolesList] = useState([]);
	
	const [BranchesList, setBranchesList] = useState([]);
	
	const [CollegesList, setCollegesList] = useState([]);

	const getRoleslist = async () => {
		let res = await getRoleslistAction();
		if (res.success) {
			let data = res.data;
			setRolesList(data);	
		}
	}
	
	const getBranchesList = async () => {
		let res = await getBranchesListAction();
		if (res.success) {
			let data = res.data;
			setBranchesList(data);	
		}
	}
	
	const getCollegesList = async () => {
		let res = await getCollegesListAction();
		if (res.success) {
			let data = res.data;
			setCollegesList(data);	
		}
	}	
	
	const getUserDetails = async () => {
		const id = window.location.href.split("/").pop();
		let res = await getUserDetailsAction({"user_id":id});
		console.log(res);
		if (res.success) {
		  setUserDetails(res.data);
		  let data = res.data;
		  setUserEmail(data.email);
		  setUserRole(data.role_id);
		  setForm((old) => {
			return { ...old, user_id: data.id, branch_id: data.branch_id, college_id: data.college_id, first_name: data.first_name, last_name: data.last_name, m_pin: data.m_pin, year: data.year };
		  });
		}
	};
	
		
	useEffect(()=>{
		getRoleslist();
        getBranchesList();
        getCollegesList();
        getUserDetails();
    },[]);
	
	const inputHandler = async (e) => {
		const { name, value } = e.target
		setForm((old) => {
			return { ...old, [name]: value }
		})
	}
	
	const inputpwdHandler = async (e) => {
		const { name, value } = e.target
		setpwdform((old) => {
			return { ...old, [name]: value }
		})
	}

   function validate() {
		let first_nameError = "";
		let branch_idError = "";
		let college_idError = "";
		let yearError = "";
		let m_pinError = "";
		
		if (form.first_name === '') {
			first_nameError = "First name is required."
		}
		if (form.branch_id === '') {
			branch_idError = "Branch is required."
		}
		if (form.college_id === '') {
			college_idError = "College is required."
		}
		if (form.year === '') {
			yearError = "Year is required."
		}
		if (form.m_pin === '') {
			m_pinError = "M pin is required."
		}
		
		if (first_nameError || branch_idError || college_idError || yearError || m_pinError) {
			setvalidationError({
			  first_nameError, branch_idError, college_idError, yearError, m_pinError
			})
			return false
		} else {
			return true
		}
	}
	
   function validatePWD() {
		let passwordError = "";
		let cpasswordError = "";
		if (pwdform.password === '') {
			passwordError = "Password is required."
		}
		if (pwdform.cpassword === '') {
			cpasswordError = "Confirm password is required."
		}
		
		if(pwdform.password !='' && pwdform.cpassword !='')
		{
			if(pwdform.password != pwdform.cpassword)
			{
				cpasswordError = "Confirm password not matched."
			}
		}
		
		if (passwordError || cpasswordError) {
			setvalidError({ passwordError, cpasswordError	})
			return false
		} else {
			setvalidError({ passwordError, cpasswordError	})
			return true
		}
	}
	const handleKeyPress = (e) => {
		// Allow only numeric values (0-9) and certain control keys
		const isValidInput = /^[0-9\b]+$/.test(e.key);

		if (!isValidInput) {
		  e.preventDefault();
		}
	  };  
	
   const SubmitForm = async (e) => {
		e.preventDefault()
		const isValid = validate();
		if (!isValid) {

		}
		else {
			let res = await UpdateUserProfileAction(form);
			if (res.success) {
				toast.success(res.msg);
			} else {
				toast.error(res.msg);
			}
		}
	}
  
   const SubmitPwdForm = async (e) => {
		e.preventDefault()
		const isValid = validatePWD();
		if (!isValid) {

		}
		else {
			let res = await UpdateUserPasswordAction(form);
			if (res.success) {
				toast.success(res.msg);
				setpwdform({ password: '', cpassword: '', user_id:id  })
				
			} else {
				toast.error(res.msg);
			}
		}
	}
  

  return (

    <>
      <Sidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <Toaster />
        <Header />
         <div className="container-lg mb-4">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-0 ms-2">
              <li className="breadcrumb-item">
               <span><a className="homelink" href={`${config.baseUrl}users`} >Home</a></span>
              </li>
              <li className="breadcrumb-item active"><span>Edit User</span></li>
            </ol>
          </nav>
        </div>
        <div className="body flex-grow-1 px-3">
        <div className="container-lg">
          <div className="row">
            
          <div className="col-md-12 mb-4">
              <div className="card mb-4">
                <div className="card-header">
					<div className="row align-items-center">				
						<div className="col-md-6 text-start">
						 <span>Edit User</span>
						</div>
						<div className="col-md-6 text-end">
							<a className="btn btn-primary editbtn" href={`${config.baseUrl}users`} >Back</a>
						</div>
					</div>
                </div>
                <div className="card-body inner-body">                
                 <form className="row g-3">	
					 					  			  
					  <div className="col-md-6">
						<label className="form-label" for="first_name">First name<span className="req-star">*</span></label>
						<input className={validationError.first_nameError ? 'form-control is-invalid' : 'form-control'} id="first_name" name="first_name" type="text" value={form.first_name} onChange={inputHandler} maxLength={10} />
						<div className="invalid-feedback">{validationError.first_nameError}</div>
					  </div>
					  					  
					  <div className="col-md-6">
						<label className="form-label" for="last_name">Last name</label>
						<input className="form-control" id="last_name" name="last_name" value={form.last_name} type="text" onChange={inputHandler} maxLength={10} />
					  </div>
					  
					  <div className="col-md-6">
						<label className="form-label" for="email">Email</label>
						<input className="form-control" id="email" name="email" value={UserEmail} type="text" readonly="true" disabled/>
					  </div>
					  
					  <div className="col-md-6">
						<label className="form-label" for="role_id">Role</label>
						<select className='form-control' aria-label="select example" id="role_id" name="role_id" value={UserRole} readonly="true" disabled>
                              <option value="">Select Role</option>
								{
									RolesList.map((xrole,index)=>{
										return(
											<option value={xrole.id}>{xrole.title}</option>
										)
									})
								}
                            </select>                           
					  </div>
					  
					 					  
					  <div className="col-md-6">
						<label className="form-label" for="branch_id">Branch<span className="req-star">*</span></label>
						<select className={validationError.branch_idError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="branch_id" name="branch_id"value={form.branch_id} onChange={inputHandler}>
                              <option value="">Select Branch</option>
								{
									BranchesList.map((xbranch,index)=>{
										return(
											<option value={xbranch.id} >{xbranch.title}</option>
										)
									})
								}
                            </select>
                            <div className="invalid-feedback">{validationError.branch_idError}</div>
					  </div>
					  
					  <div className="col-md-6">
						<label className="form-label" for="college_id">College<span className="req-star">*</span></label>
						<select className={validationError.college_idError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="college_id" name="college_id" value={form.college_id} onChange={inputHandler}>
                              <option value="">Select College</option>
								{
									CollegesList.map((xcollege,index)=>{
										return(
											<option value={xcollege.id} >{xcollege.title}</option>
										)
									})
								}
                            </select>
                            <div className="invalid-feedback">{validationError.college_idError}</div>
					  </div>
					  
					  <div className="col-md-6">
						<label className="form-label" for="year">Year<span className="req-star">*</span></label>
						<input className={validationError.yearError ? 'form-control is-invalid' : 'form-control'} id="year" name="year" type="text" value={form.year} onChange={inputHandler} maxLength={4} onKeyPress={handleKeyPress} />
						<div className="invalid-feedback">{validationError.yearError}</div>
					  </div>
					  
					  <div className="col-md-6">
						<label className="form-label" for="m_pin">M Pin<span className="req-star">*</span></label>
						<input className={validationError.m_pinError ? 'form-control is-invalid' : 'form-control'} id="m_pin" name="m_pin" type="text" value={form.m_pin} onChange={inputHandler} maxLength={10} />
						<div className="invalid-feedback">{validationError.m_pinError}</div>
					  </div>		  
					  
					  <div className="col-12 text-end">
						<button className="btn btn-primary" type="submit" onClick={SubmitForm} >Update</button>
					  </div>					  
				  </form>                
                </div>
                 <div className="card-body inner-body">  
					 <h4 className="mb-4">Change Password</h4>              
                 <form className="row g-3 mb-4 align-items-end">						 					  			  
					  <div className="col-md-6">
						<label className="form-label" for="password">Password<span className="req-star">*</span></label>
						<input className={validError.passwordError ? 'form-control is-invalid' : 'form-control'} id="password" name="password" type="password" value={pwdform.password} onChange={inputpwdHandler} />
						<div className="invalid-feedback">{validError.passwordError}</div>
					  </div>
					  					  
					  <div className="col-md-6">
						<label className="form-label" for="cpassword">Confirm Password<span className="req-star">*</span></label>
						<input className={validError.cpasswordError ? 'form-control is-invalid' : 'form-control'}  id="cpassword" name="cpassword" value={form.cpassword} type="password" onChange={inputpwdHandler} value={pwdform.cpassword} />
						<div className="invalid-feedback">{validError.cpasswordError}</div>
					  </div> 
					  <div className="col-12 text-end pt-25">
						<button className="btn btn-primary" type="submit" onClick={SubmitPwdForm} >Change Password</button>
					  </div>					  
				  </form>                
                </div>
              </div>
            </div>
            
            
            
            
          </div>          
        </div>
      </div>    
      <Footer />
      </div>
    </>
  )

}
export default EditUser;
