import React, { useEffect, useState } from 'react'
import Header from '../directives/header'
import Footer from '../directives/footer'
import Sidebar from '../directives/sidebar'
import { getRoleslistAction } from '../Action/action';

const Roles = () => {

	const [RolesList, setRolesList] = useState([]);

	const getRoleslist = async () => {
		let res = await getRoleslistAction();
		if (res.success) {
			let data = res.data;
			
			setRolesList(data);	
			
			console.log(RolesList);
		}
	}
      
   useEffect(()=>{
        getRoleslist();
    },[]);
  
  
  

  return (

    <>
      <Sidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        
        <Header />
        
        <div className="body flex-grow-1 px-3">
        <div className="container-lg">
          <div className="row">
            
          <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header">Roles</div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead className="table-light fw-semibold">
                        <tr className="align-middle">
                          <th>#</th>
                          <th>Title</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
							RolesList.map((xrole,index)=>{
								return(
									<tr key={index}>
										<td>{index+1}</td>
										<td>{xrole.title}</td>
										<td>
										  <div className="dropdown dropup">
											  <button className="btn btn-transparent p-0" type="button" data-coreui-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												<svg className="icon">
												  <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-options"></use>
												</svg>
											  </button>
											  <div className="dropdown-menu dropdown-menu-end"><a className="dropdown-item" href="#">Info</a><a className="dropdown-item" href="#">Edit</a><a className="dropdown-item text-danger" href="#">Delete</a></div>
											</div>
										</td>
										
									</tr>
								)
							})
						}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>



          </div>
          
        </div>
      </div>    
      <Footer />
      </div>
    </>
  )

}
export default Roles;
