import React, { useEffect, useState } from 'react'
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import Pagination from './Pagination';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Sidebar from '../directives/sidebar'
import {getPackagesListAction, getTotalPackagesListAction, PackageBlockUnBlockAction, deletePackageAction, createPackageAction, getPackageDetailsAction, updatePackageAction } from '../Action/action';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        zindex:'99999999999999999999'
    },
};

const Packages = () => {

	const [PackagesList, setPackagesList] 			= useState([]);	  
	const [page, setPage] 							= useState(1); 
	const [totalPages, settotalPages] 				= useState(0);
	const [totalRecords, settotalRecords] 			= useState(0);
	const [pagingspinloader, setpagingspinloader] 	= useState(1);  
	const [titleFilter, SettitleFilter] 			= useState('');	
	const [validationError, setvalidationError] 	= useState({});	
    const [modalopen, setModalopen] 				= useState(0);	
    const [editmodalopen, seteditmodalopen] 				= useState(0);	
	const [isDialogOpen, setDialogOpen] 			= useState(false);
	const [packageDetails, setPackageDetails] 		= useState({
       title: '', package_type: '', is_active: ''   
    });
	const [editpackageDetails, seteditPackageDetails] = useState({
       id:'', title: '', package_type: '', is_active: ''   
    });
	useEffect(()=>{
	    setpagingspinloader(1);	   
		getTotalPackagesListAPI();
		getPackagesListAPI();
    },[]);
	const getTotalPackagesListAPI = async () => {
        let res = await getTotalPackagesListAction({title:titleFilter});
        if (res.success) {
            settotalPages(res.data);
            settotalRecords(res.totalRecords);
             setpagingspinloader(0);
            
		}
        else
        {
			settotalPages(0);
			settotalRecords(0);		
			setpagingspinloader(0);	
		}
		
		
    }
	const getPackagesListAPI = async () => {
		let res = await getPackagesListAction({title:titleFilter, page:page});
		if (res.success) {      
		  setPackagesList(res.data);
		}
		else
		{
		setPackagesList([]);	
		}
	  };
	const onPageChanged = async (data)  => {
		const { currentPage, totalPages, pageLimit } = data;
		const offset = (currentPage - 1) * pageLimit;
		let res = await getPackagesListAction({title:titleFilter, page:offset});
        if (res.success) {
            setPackagesList(res.data);
        }
        else
		{
		setPackagesList([]);	
		}

  }	
	const deletePackage = async (id) => {
    Swal.fire({
		html: `
				<div>
				  <h4>Warning</h4>
				  <p>You want to Delete this package!</p>
				  <div>
					<img src="${config.baseUrl}images/warning.png" alt="Warning Image" />
				  </div>
				</div>
			  `,      
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Deleted it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deletePackageAction({ id: id });
        if (res.success) {
          getPackagesListAPI();  
           toast.success("Package deleted successfully!");
        } else {         
          toast.error(res.msg);       
        }
      }
    });
  };
	const handleStatus = async (id, is_active) => {
        Swal.fire({
			 html: `
				<div>
				  <h4>Warning</h4>
				  <p>${is_active === 0 ? "Are you sure you want to disable this package?" : "Are you sure you want to enable this package?"}</p>
				  <div>
					<img src="${config.baseUrl}images/warning.png" alt="Warning Image" />
				  </div>
				</div>
			  `,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: is_active == 0 ? 'Yes, Disable it!' : 'Yes, Enable it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await await PackageBlockUnBlockAction({ 'id': id, 'is_active': is_active });
                if (res.success) {
                    getPackagesListAPI();
                     if(is_active==0)
						toast.success("Package disabled successfully!");
                    else
                     toast.success("Package enabled successfully!"); 
                    
                } else {
                    toast.error(res.msg);      
                }
            }
        })
    }
	const SettFilter = async (e) => {
        SettitleFilter(e.target.value);        
    }
	const FilterPackages = async (e) => {
		setPage(1);	
        getTotalPackagesListAPI();
        getPackagesListAPI();       
    }
	const ClearPackages = async (e) => {
	  SettitleFilter('');
	  setPage(1);	
      let res = await getTotalPackagesListAction({title:''});
        if (res.success) {
            settotalPages(res.data);
            settotalRecords(res.totalRecords);
            setpagingspinloader(0);            
		}
        else
        {
			settotalPages(0);
			settotalRecords(0);		
			setpagingspinloader(0);	
		}
      let list = await getPackagesListAction({title:'',page:1});
		if (list.success) {
			setPackagesList(list.data);	
		}
		else
		{
			setPackagesList([]);	
		}      
    }
    const closebutton = async () => {
        setModalopen(0);
    } 
    const closebutton2 = async () => {
        seteditmodalopen(0);
    } 
    function afterOpenModal() {
        //subtitle.style.color = '#f00';
    }
    const inputHandler = async (e) => {
		const { name, value } = e.target
		setPackageDetails((old) => {
			return { ...old, [name]: value }
		})
	}
    const inputEditHandler = async (e) => {
		const { name, value } = e.target
		seteditPackageDetails((old) => {
			return { ...old, [name]: value }
		})
	}
   
     
	function validate() {
	
		let titleError = "";
		let package_typeError = "";
		let is_activeError = "";		
		if (packageDetails.title === '') {
		  titleError = "Title is required."
		}
		if (packageDetails.package_type === '') {
			package_typeError = "Package type is required."
		}
		if (packageDetails.is_active === '') {
			is_activeError = "Status is required."
		}	
		
				
		if (titleError || package_typeError || is_activeError) {
			setvalidationError({
			  titleError, package_typeError, is_activeError})
			return false
		} else {
			setvalidationError({});
			return true
		}
	}
	  
	function validateedit() {
	
		let edittitleError = "";
		let editpackage_typeError = "";
		let editis_activeError = "";		
		if (editpackageDetails.title === '') {
		  edittitleError = "Title is required."
		}
		if (editpackageDetails.package_type === '') {
			editpackage_typeError = "Package type is required."
		}
		if (editpackageDetails.is_active === '') {
			editis_activeError = "Status is required."
		}
		if (edittitleError || editpackage_typeError || editis_activeError) {
			setvalidationError({
			  edittitleError, editpackage_typeError, editis_activeError})
			return false
		} else {
			setvalidationError({});
			return true
		}
	}
	  
   const AddPackage = () => {
	   setvalidationError({});
	   setPackageDetails({title: '', package_type: '', is_active: ''});	   
	   setModalopen(1);        
    } 
   const SubmitPackage = async (e) => {
		e.preventDefault()
		const isValid = validate();
		if (!isValid) {

		}
		else {
			let res = await createPackageAction(packageDetails);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}addpackagemods/`+res.data;
				}, 1200);
				
			} else {
				toast.error(res.msg);
			}
		}
	}
   
   const SubmitEditPackage = async (e) => {
		e.preventDefault()
		const isValid = validateedit();
		if (!isValid) {

		}
		else {
			let res = await updatePackageAction(editpackageDetails);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}editpackagemods/`+res.data;
				}, 1200);
				
			} else {
				toast.error(res.msg);
			}
		}
	}
   
   const editPackage = async (id) => {
	   setvalidationError({});
		let res = await getPackageDetailsAction({id:id});
		if (res.success) {      
		  seteditPackageDetails({id:id, title: res.data.title, package_type: res.data.package_type, is_active: res.data.is_active});
		}
	seteditmodalopen(1);        
    } 
   
  return (

    <>
      <Sidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <Toaster />
        <Header />       
         <div className="container-lg mb-4">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-0 ms-2">
              <li className="breadcrumb-item">
               <span><a className="homelink" href={`${config.baseUrl}users`} >Home</a></span>
              </li>
              <li className="breadcrumb-item active"><span>Packages</span></li>
            </ol>
          </nav>
        </div>
        <div className="body flex-grow-1 px-3">
        <div className="container-lg">
          <div className="row">
            
          <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header">	
					<div className="row align-items-center">				
						<div className="col-md-6 text-start">
						 <span>Packages</span>
						</div>
						<div className="col-md-6 text-end">
						</div>
					</div>				
					<div className="row mb-4 mt-4 align-items-end">
						<div className="col-lg-4 col-xl-4 mt-3">	
						<label className="form-label">Search Packages</label>						 
						  <input type="text" name="title" placeholder="Search packages..." id="input-name" data-oc-target="autocomplete-name" className="form-control" autocomplete="off" onChange={SettFilter} value={titleFilter}/>
						</div>
						<div className="col-lg-5  mt-3">
						  <button type="button" id="button-filter" className="btn btn-info" onClick={() => FilterPackages()}><i className="fa fa-search"></i> Search</button>
						  &nbsp;&nbsp;&nbsp;
						  <button type="button" id="button-filter" className="btn btn-primary" onClick={() => ClearPackages()}>Clear</button>
						</div>
						<div className="col-lg-3 mt-3 text-end">
							<a className="btn btn-primary createbtn" href='javascript:;' onClick={() => { AddPackage() }} ><i className="fa fa-plus"></i> Add New</a>
						</div>
					</div>	
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead className="table-light fw-semibold">
                        <tr className="align-middle table-heading">
                          <th>SR.</th>
                          <th>Title</th>
                          <th>Type</th>
                          <th>Status</th>
                          <th  style={{ minWidth: '345px' }} className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                         { PackagesList.length> 0 ?
							PackagesList.map((item, index) => (
								<>
									<tr key={index}>
										<td>{index+1}</td>
										<td>{item.title}</td>										
										<td>{item.package_type}</td>										
										<td >
											<div className="form-check form-switch">
											  <input
												className="form-check-input"
												id={`flexSwitchCheckChecked_${index}`}
												type="checkbox"
												checked={item.is_active === 1}
												onChange={() => handleStatus(item.id, item.is_active === 1 ? 0 : 1)}
											  />
											</div>
										</td>
										<td className="text-right">
										<button type="button" className="btn btn-primary editbtn" onClick={() => editPackage(item.id)}><i className="far fa-edit"></i> Edit</button>
											&nbsp;
											<button type="button" className="btn btn-danger deletebtn" onClick={() => deletePackage(item.id)}><i className='fa fa-trash'></i> Delete</button>&nbsp;
											<Link to={`${config.baseUrl}packagemodlist/` + item.id}>
												<button className='btn btn-primary createbtn module-list'><i className="fa fa-list"></i> Modules</button>
											</Link>									  
										</td>
										
									</tr>
									</>
								)
							)
							:
							(
								<tr><td colspan="5" className="text-center">No package found.</td></tr>
							)				 
						}
                      
                      </tbody>
                    </table>
                     {totalPages > 0 ?										  
						 <Pagination totalRecords={totalRecords} totalPages={totalPages} pageNeighbours={page} onPageChanged={onPageChanged}   />
						 : ''
					 }	
                  </div>
                </div>
              </div>
            </div>
          </div>          
        </div>
      </div> 
        <div className={modalopen == '0' ? "modal fade cart-modal mc-popup" : "mc-popup modal fade cart-modal show"} role="dialog" style={{ background: '0% 0% / cover rgba(6, 6, 6, 0.32)', display: modalopen == '0' ? 'none' : 'block' }}  id="myModal" tabindex="-1" data-backdrop="false">
		  <div className="modal-dialog modal-dialog-centered modal-lg">
			<div className="modal-content">
			  <div className="modal-header">				
				<button className="btn-close" type="button" onClick={closebutton} aria-label="Close"></button>
			  </div>
			  <div className="modal-body">
			  <div className="row align-items-center">
				  <div className="col-md-5">
					<div className="topic-image text-center">
						<img src={`${config.baseUrl}images/pakage.png`} />
				   </div>
			   </div>
			   <div className="col-md-7">
				<div className="row">
                  <div className="col-md-12 pb-4">
						<label className="form-label" for="first_name">Package Name<span className="req-star">*</span></label>
						<input className={validationError.titleError ? 'form-control is-invalid' : 'form-control'} id="title" name="title" type="text" placeholder="Type package name" onChange={inputHandler} maxLength={30} />
						<div className="invalid-feedback">{validationError.titleError}</div>
                                                   
					  </div>
					 <div className="col-md-6 pb-4">
						<label className="form-label" for="package_type">Package type<span className="req-star">*</span></label>
						<select className={validationError.package_typeError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="package_type" name="package_type" onChange={inputHandler}>
                              <option value="">Select package type</option>								
                              <option value="Paid">Paid</option>								
                              <option value="Free">Free</option>								
                            </select>
                            <div className="invalid-feedback">{validationError.package_typeError}</div>
					  </div>					 				
					  <div className="col-md-6 pb-4">
						<label className="form-label" for="is_active">Status<span className="req-star">*</span></label>
						<select className={validationError.is_activeError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="is_active" name="is_active" onChange={inputHandler}>
                              <option value="">Select Status</option>								
                              <option value="1">Active</option>								
                              <option value="0">In-Active</option>								
                            </select>
                            <div className="invalid-feedback">{validationError.is_activeError}</div>
					  </div>
					<div className="col-md-12 pt-25 text-start mt-3">
						<button className="btn btn-primary" type="button" onClick={SubmitPackage}>Create</button>
					 </div>
					 </div>
					  </div>
					 
					 
					  
			  </div>
			  </div>			 
			</div>
		  </div>
		</div>  
		
		 <div className={editmodalopen == '0' ? "modal fade cart-modal mc-popup" : "mc-popup modal fade cart-modal show"} role="dialog" style={{ background: '0% 0% / cover rgba(6, 6, 6, 0.32)', display: editmodalopen == '0' ? 'none' : 'block' }}  id="myModal" tabindex="-1" data-backdrop="false">
		  <div className="modal-dialog modal-dialog-centered modal-lg">
			<div className="modal-content">
			  <div className="modal-header">				
				<button className="btn-close" type="button" onClick={closebutton2} aria-label="Close"></button>
			  </div>
			  <div className="modal-body">
			  <div className="row align-items-center">
				  <div className="col-md-5">
					<div className="topic-image text-center">
						<img src={`${config.baseUrl}images/pakage.png`} />
				   </div>
			   </div>
			   <div className="col-md-7">
				   <div className="row">
                  <div className="col-md-12 pb-4">
						<label className="form-label" for="first_name">Package Name<span className="req-star">*</span></label>
						<input className={validationError.titleError ? 'form-control is-invalid' : 'form-control'} id="title" name="title" type="text" placeholder="Enter package name" onChange={inputEditHandler} value={editpackageDetails?.title} maxLength={30} />
						<div className="invalid-feedback">{validationError.titleError}</div>
                                                   
					  </div>
					 <div className="col-md-6 pb-4">
						<label className="form-label" for="package_type">Package type<span className="req-star">*</span></label>
						<select className={validationError.package_typeError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="package_type" name="package_type" onChange={inputEditHandler} value={editpackageDetails?.package_type}>
                              <option value="">Select package type</option>								
                              <option value="Paid">Paid</option>								
                              <option value="Free">Free</option>								
                            </select>
                            <div className="invalid-feedback">{validationError.package_typeError}</div>
					  </div>					 				
					  <div className="col-md-6 pb-4">
						<label className="form-label" for="is_active">Status<span className="req-star">*</span></label>
						<select className={validationError.is_activeError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="is_active" name="is_active" onChange={inputEditHandler} value={editpackageDetails?.is_active}>
                              <option value="">Select Status</option>								
                              <option value="1">Active</option>								
                              <option value="0">In-Active</option>								
                            </select>
                            <div className="invalid-feedback">{validationError.is_activeError}</div>
					  </div>
					<div className="col-md-12 pt-25 text-left mt-3">
						<button className="btn btn-primary" type="button" onClick={SubmitEditPackage}>Update</button>
					 </div>
					</div>
					</div>  
					</div>
			  </div>
			  </div>			 
			</div>
		  </div>		
		  
      <Footer />
      </div>
    </>
  )

}
export default Packages;
