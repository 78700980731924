import React, { useEffect, useState } from 'react'
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';

import Header from '../directives/header'
import Footer from '../directives/footer'
import Sidebar from '../directives/sidebar'

import { getRoleslistAction,getBranchesListAction,getCollegesListAction,CreateUserAction } from '../Action/action';

const CreateUser = () => {
	
	const [RolesList, setRolesList] = useState([]);	
	const [CollegesList, setCollegesList] = useState([]);
	const [BranchesList, setBranchesList] = useState([]);
	const [form, setForm] = useState({ email: '', password: '', role_id: '', branch_id: '', college_id: '', first_name: '', last_name: '', m_pin: '', year: '' });
	const [validationError, setvalidationError] = useState({});

	const getRoleslist = async () => {
		let res = await getRoleslistAction();
		if (res.success) {
			let data = res.data;
			setRolesList(data);	
		}
	}

	const getBranchesList = async () => {
		let res = await getBranchesListAction();
		if (res.success) {
			let data = res.data;
			setBranchesList(data);	
		}
	}

	const getCollegesList = async () => {
		let res = await getCollegesListAction();
		if (res.success) {
			let data = res.data;
			setCollegesList(data);	
		}
	}
		
	useEffect(()=>{
		getRoleslist();
        getBranchesList();
        getCollegesList();
    },[]);
	
	const inputHandler = async (e) => {
		const { name, value } = e.target
		setForm((old) => {
			return { ...old, [name]: value }
		})
	}
	const handleKeyPress = (e) => {
		// Allow only numeric values (0-9) and certain control keys
		const isValidInput = /^[0-9\b]+$/.test(e.key);

		if (!isValidInput) {
		  e.preventDefault();
		}
	  };  
	
   function validate() {
		let emailError = "";
		let passwordError = "";
		let first_nameError = "";
		let role_idError = "";
		let branch_idError = "";
		let college_idError = "";
		let yearError = "";
		let m_pinError = "";
		
		if (form.email === '') {
		  emailError = "Email is required."
		}
		if (form.password === '') {
			passwordError = "Password is required."
		}
		if (form.first_name === '') {
			first_nameError = "First name is required."
		}
		if (form.role_id === '') {
			role_idError = "Role is required."
		}
		if (form.branch_id === '') {
			branch_idError = "Branch is required."
		}
		if (form.college_id === '') {
			college_idError = "College is required."
		}
		if (form.year === '') {
			yearError = "Year is required."
		}
		if (form.m_pin === '') {
			m_pinError = "M pin is required."
		}
		if(form.email != '')
		{
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			if(emailRegex.test(form.email) === false)
			{
				emailError = "Please enter valid email."
			}
		}
		
		if (emailError || passwordError || first_nameError || role_idError || branch_idError || college_idError || yearError || m_pinError) {
			setvalidationError({
			  emailError, passwordError, first_nameError, role_idError, branch_idError, college_idError, yearError, m_pinError
			})
			return false
		} else {
			return true
		}
	}
	
	const SubmitForm = async (e) => {
		e.preventDefault()
		const isValid = validate();
		if (!isValid) {

		}
		else {
			let res = await CreateUserAction(form);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}users`;
				}, 1200);
			} else {
				toast.error(res.msg);
			}
		}
	}
 

  return (

    <>
      <Sidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <Toaster />
        <Header />
         <div className="container-lg mb-4">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-0 ms-2">
              <li className="breadcrumb-item">
               <span><a className="homelink" href={`${config.baseUrl}users`} >Home</a></span>
              </li>
              <li className="breadcrumb-item active"><span>Create User</span></li>
            </ol>
          </nav>
        </div>
        <div className="body flex-grow-1 px-3">
        <div className="container-lg">
          <div className="row">
            
          <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header">
					<div className="row align-items-center">				
						<div className="col-md-6 text-start">
						 <span>Create User</span>
						</div>
						<div className="col-md-6 text-end">
							<a className="btn btn-primary editbtn" href={`${config.baseUrl}users`} >Back</a>
						</div>
					</div>
                </div>
                <div className="card-body inner-body">                
                 <form className="row g-3">
					 
					  <div className="col-md-6">
						<label className="form-label" for="first_name">Email<span className="req-star">*</span></label>
						<input className={validationError.emailError ? 'form-control is-invalid' : 'form-control'} id="email" name="email" placeholder="Enter email address" type="text" onChange={inputHandler} />
						<div className="invalid-feedback">{validationError.emailError}</div>
					  </div>
					  
					  <div className="col-md-6">
						<label className="form-label" for="password">Password<span className="req-star">*</span></label>
						<input className={validationError.passwordError ? 'form-control is-invalid' : 'form-control'} id="password" name="password" placeholder="Enter password" type="password" onChange={inputHandler} />
						<div className="invalid-feedback">{validationError.passwordError}</div>
					  </div>
					  
					  <div className="col-md-6">
						<label className="form-label" for="first_name">First name<span className="req-star">*</span></label>
						<input className={validationError.first_nameError ? 'form-control is-invalid' : 'form-control'} id="first_name" name="first_name" placeholder="Enter first name" type="text" onChange={inputHandler} maxLength={10} />
						<div className="invalid-feedback">{validationError.first_nameError}</div>
					  </div>
					  
					  <div className="col-md-6">
						<label className="form-label" for="last_name">Last name</label>
						<input className="form-control" id="last_name" name="last_name" placeholder="Enter last name" type="text" onChange={inputHandler} maxLength={10} />
					  </div>
					  
					  <div className="col-md-6">
						<label className="form-label" for="role_id">Role<span className="req-star">*</span></label>
						<select className={validationError.role_idError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="role_id" name="role_id" onChange={inputHandler}>
                              <option value="">Select Role</option>
								{
									RolesList.map((xrole,index)=>{
										return(
											<option value={xrole.id}>{xrole.title}</option>
										)
									})
								}
                            </select>
                            <div className="invalid-feedback">{validationError.role_idError}</div>
					  </div>
					  
					  <div className="col-md-6">
						<label className="form-label" for="branch_id">Branch<span className="req-star">*</span></label>
						<select className={validationError.branch_idError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="branch_id" name="branch_id" onChange={inputHandler}>
                              <option value="">Select Branch</option>
								{
									BranchesList.map((xbranch,index)=>{
										return(
											<option value={xbranch.id}>{xbranch.title}</option>
										)
									})
								}
                            </select>
                            <div className="invalid-feedback">{validationError.branch_idError}</div>
					  </div>
					  
					  <div className="col-md-6">
						<label className="form-label" for="college_id">College<span className="req-star">*</span></label>
						<select className={validationError.college_idError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="college_id" name="college_id" onChange={inputHandler}>
                              <option value="">Select College</option>
								{
									CollegesList.map((xcollege,index)=>{
										return(
											<option value={xcollege.id}>{xcollege.title}</option>
										)
									})
								}
                            </select>
                            <div className="invalid-feedback">{validationError.college_idError}</div>
					  </div>
					  
					  <div className="col-md-6">
						<label className="form-label" for="year">Year<span className="req-star">*</span></label>
						<input className={validationError.yearError ? 'form-control is-invalid' : 'form-control'} id="year" name="year" type="text" placeholder="Enter year" onChange={inputHandler}  pattern="[0-9]*" maxLength={4} onKeyPress={handleKeyPress} />
						<div className="invalid-feedback">{validationError.yearError}</div>
					  </div>
					  
					  <div className="col-md-6">
						<label className="form-label" for="m_pin">M Pin<span className="req-star">*</span></label>
						<input className={validationError.m_pinError ? 'form-control is-invalid' : 'form-control'} id="m_pin" name="m_pin" type="text" placeholder="Enter mpin" onChange={inputHandler} maxLength={10} />
						<div className="invalid-feedback">{validationError.m_pinError}</div>
					  </div>					  
					  
					  <div className="col-12 text-end">
						<button className="btn btn-primary" type="submit" onClick={SubmitForm} >Save</button>
					  </div>
					  					  
				  </form>                
                </div>
              </div>
            </div>
          </div>          
        </div>
      </div>    
      <Footer />
      </div>
    </>
  )

}
export default CreateUser;
