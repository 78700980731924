import React, { useEffect, useState } from 'react'
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import JoditEditor from "jodit-react";
import Header from '../directives/header'
import Footer from '../directives/footer'
import Sidebar from '../directives/sidebar'

import { getBranchesListAction,getDomainsListAction,getTopicListAction,getModuleDetailsAction,updateModuleAction, getModulesTypesAction } from '../Action/action';

const EditModule = () => {	
	const { id } = useParams();		
	const [BranchesList, setBranchesList] = useState([]);		
	const [branch_id, setbranch_id] = useState(null);		
	const [DomainsList, setDomainsList] = useState([]);	
	const [domain_id, setdomain_id] = useState(null);			
	const [topic_id, settopic_id] = useState(null);			
	const [TopicsList, setTopicsList] = useState([]);		
	const [FilterTopicsList, setFilterTopicsList] = useState([]);
	const [spindetailloader, setspindetailloader] = useState(0);
	const [moduletypeList, setmoduletypeList] = useState([]);	
	const [moduledata, setmoduledata] = useState({});
	const [image_preview, setimage_preview] = useState('');
    const [image_file, setimage_file] = useState('');		
	const editorHeight = '400px'; 
	useEffect(()=>{
		getBranchesListAPI();       
		getDomainsListAPI();       
		getTopicListAPI();
		getModulesTypesAPI();
		getModuleDetailsAPI();       
    },[]);
    
    
     const getModuleDetailsAPI = async () => {
		setspindetailloader(1);
        let res = await getModuleDetailsAction({ 'id': id });
        if (res.success) {
            setmoduledata(res.data)            
            setFilterTopicsList(res.topics)
            setbranch_id(res.data.branch_id);	
			setdomain_id(res.data.domain_id);
			settopic_id(res.data.topic_id);
			setspindetailloader(0);
           }
    } 
	 const PicChange = async (e) => {
        e.preventDefault()
        let image_as_base64 = URL.createObjectURL(e.target.files[0])
        let image_as_files = e.target.files[0];
        let imageType = e.target.files[0].type;
        if (imageType == 'image/jpeg' || imageType == 'image/jpg' || imageType == 'image/png') {
            setimage_file(image_as_files);
            setimage_preview(image_as_base64);
            setmoduledata((old) => {
                return { ...old, ['image_url']: image_as_files }
            })
        } else {
            toast.error('File type wrong please select JPG, JPEG or PNG.');
        }
    }
	const getBranchesListAPI = async () => {
		let res = await getBranchesListAction();
		if (res.success) {
			setBranchesList(res.data);	
		}
	}
	const getDomainsListAPI = async () => {
		let res = await getDomainsListAction();
		if (res.success) {
			setDomainsList(res.data);	
		}
	}
	
	const getModulesTypesAPI = async () => {
		let res = await getModulesTypesAction();
		if (res.success) {
			setmoduletypeList(res.data);	
		}
	}
	
	const getTopicListAPI = async () => {
		let res = await getTopicListAction();
		if (res.success) {
			setTopicsList(res.data);	
		}
	}
	
	const [validationError, setvalidationError] = useState({});

	const inputHandler = async (e) => {
		const { name, value } = e.target;
		setmoduledata((old) => {
		  return { ...old, [name]: value };
		});
	  };
	
	const app_descriptionHandler = async (e) => {
		setmoduledata((old) => {
		  return { ...old, 'wrapper_app_description': e }
		})
	  }
	const web_contentHandler = async (e) => {
		setmoduledata((old) => {
		  return { ...old, 'web_content': e }
		})
	  }
	
	const filterBranchHandler = async (e) => {
	 setbranch_id(e.target.value);
	 let searchBranch = e.target.value; 
	 if(searchBranch != null && domain_id !=null){
		 settopic_id('');
		const result = TopicsList.filter(data => String(data.branch_id).includes(searchBranch) && String(data.domain_id).includes(domain_id)); 
		setFilterTopicsList(result); 		
	  } 
		
	}
	const filterDomainHandler = async (e) => {
		 setdomain_id(e.target.value); 		 
		 let searchDomain = e.target.value; 
		 if(searchDomain != null && branch_id !=null){
			 settopic_id('');
			const result = TopicsList.filter(data => String(data.branch_id).includes(branch_id) && String(data.domain_id).includes(searchDomain)); 
			setFilterTopicsList(result); 		
		  }
	}
	const inputHandlerTopic = async (e) => {
		 settopic_id(e.target.value); 
	}
	const handleKeyPress = (e) => {
		// Allow only numeric values (0-9) and certain control keys
		const isValidInput = /^[0-9\b]+$/.test(e.key);

		if (!isValidInput) {
		  e.preventDefault();
		}
	  }; 
	
   function validate() {
		let titleError = "";
		let topic_idError = "";
		let is_activeError = "";
		let versionError = "";
		let imageError = "";
		let module_type_idError="";
		
		moduledata.topic_id = topic_id;
				
		if (moduledata.title === '') {
		  titleError = "Title is required."
		}
		if (moduledata.topic_id === '') {
			topic_idError = "Topic is required."
		}
		if (moduledata.is_active === '') {
			is_activeError = "Status is required."
		}
		if (moduledata.version === '') {
			versionError = "Vesrion is required."
		}
		if (moduledata.module_type_id === '' || moduledata.module_type_id === 0) {
			module_type_idError = "Module type is required."
		}
		if (image_file == '' && moduledata.image_url == "") {
            imageError = "Image field is required."
        } 
				
		if (titleError || topic_idError || is_activeError || versionError || imageError || module_type_idError) {
			setvalidationError({
			  titleError, topic_idError, is_activeError, versionError, imageError, module_type_idError
			})
			return false
		} else {
			setvalidationError({
			  titleError, topic_idError, is_activeError, versionError, imageError, module_type_idError
			})
			return true
		}
	}
	
	const UpdateModule = async (e) => {
		e.preventDefault()
		const isValid = validate();
		if (!isValid) {

		}
		else {
			if (!image_file) {
				moduledata.old_image = moduledata.image_url;
			}
			let res = await updateModuleAction(moduledata);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}modules`;
				}, 1200);
			} else {
				toast.error(res.msg);
			}
		}
	}
  

  return (

    <>
      <Sidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <Toaster />
        <Header />
         <div className="container-lg mb-4">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-0 ms-2">
              <li className="breadcrumb-item">
               <span><a className="homelink" href={`${config.baseUrl}users`} >Home</a></span>
              </li>
              <li className="breadcrumb-item active"><span>Edit Module</span></li>
            </ol>
          </nav>
        </div>
        <div className="body flex-grow-1 px-3">
        <div className="container-lg">
          <div className="row">
            
          <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header">
					<div className="row align-items-center">				
						<div className="col-md-6 text-start">
						 <span>Edit Module</span>
						</div>
						<div className="col-md-6 text-end">
							<a className="btn btn-primary editbtn" href={`${config.baseUrl}modules`} >Back</a>
						</div>
					</div>
                </div>
                <div className="card-body inner-body">                
                 <form className="row g-3">
                  <div className="col-md-6">
						<label className="form-label" for="domain_id">Course<span className="req-star">*</span></label>
						<select className="form-control" aria-label="select example" id="domain_id" name="domain_id" onChange={filterDomainHandler} value={domain_id}>
                              <option value="">Select Course</option>
								{
									DomainsList.map((item,index)=>{
										return(
											<option value={item.id}>{item.title}</option>
										)
									})
								}
                            </select>
                            <div className="invalid-feedback">{validationError.branch_idError}</div>
					  </div>
                  <div className="col-md-6">
						<label className="form-label" for="branch_id">Branch<span className="req-star">*</span></label>
						<select className="form-control" aria-label="select example" id="branch_id" name="branch_id" onChange={filterBranchHandler} value={branch_id}>
                              <option value="">Select Branch</option>
								{
									BranchesList.map((item)=>{
										return(
											<option value={item.id}>{item.title}</option>
										)
									})
								}
                            </select>                           
					  </div>
					  
					  
					  
					   <div className="col-md-6">
						<label className="form-label" for="topic_id">Topic<span className="req-star">*</span></label>
						<select className={validationError.topic_idError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="topic_id" name="topic_id" onChange={inputHandlerTopic} value={topic_id} >
                              <option value="">Select Topic</option>
							{spindetailloader == '0' ?	
									FilterTopicsList.map((item,index)=>{
										return(
											<option value={item.id}>{item.title}</option>
										)
									})								
								:
								'<i className="fa fa-spinner fa-spin validat"></i>'
							}
                            </select>
                            <div className="invalid-feedback">{validationError.topic_idError}</div>
					  </div>
					 
					  <div className="col-md-6">
						<label className="form-label" for="title">Title<span className="req-star">*</span></label>
						<input className={validationError.titleError ? 'form-control is-invalid' : 'form-control'} id="title" name="title" type="text" onChange={inputHandler} value={moduledata?.title} maxLength={30} />
						<div className="invalid-feedback">{validationError.titleError}</div>
					  </div>
					  
					  <div className="col-md-6">
						<label className="form-label" for="version">Version<span className="req-star">*</span></label>
						<input className={validationError.versionError ? 'form-control is-invalid' : 'form-control'} id="version" name="version" type="text" onChange={inputHandler} value={moduledata?.version} onKeyPress={handleKeyPress} />	
						<div className="invalid-feedback">{validationError.versionError}</div>				
					  </div>	  
					  <div className="col-md-6">
						<label className="form-label" for="first_name">Video URL</label>
						<input className='form-control' id="video_url" name="video_url" type="text" onChange={inputHandler} value={moduledata?.video_url} />						
					  </div>
					  <div className="col-md-6">
						<label className="form-label" for="module_type_id">Module Type<span className="req-star">*</span></label>
						<select className={validationError.module_type_idError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="module_type_id" name="module_type_id" onChange={inputHandler} value={moduledata?.module_type_id}>
                              <option value="">Select Module Type</option>
								{
									moduletypeList.map((item)=>{
										return(
											<option value={item.id}>{item.title}</option>
										)
									})
								}
                            </select>   
                            <div className="invalid-feedback">{validationError.module_type_idError}</div>                        
					  </div>		
					   <div className="col-md-6">
						<label className="form-label" for="is_active">Status<span className="req-star">*</span></label>
						<select className={validationError.is_activeError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="is_active" name="is_active" onChange={inputHandler} value={moduledata?.is_active}>
                              <option value="">Select Status</option>								
                              <option value="1">Active</option>								
                              <option value="0">In-Active</option>								
                            </select>
                            <div className="invalid-feedback">{validationError.is_activeError}</div>
					  </div>
					    <div className="col-md-12 mt-5">
					   <label className="form-label description-label" for="first_name">Wrapper app description</label>					  
					 <JoditEditor
                              onChange={app_descriptionHandler}
                              value={moduledata?.wrapper_app_description}
                              name="wrapper_app_description"
                              id="wrapper_app_description"
                              style={{ height: editorHeight }}
                              className="test"
                            />
                            </div>    
                    <div className="col-md-12 mt-5">
					   <label className="form-label description-label" for="first_name">Web portal description</label>              
					 <JoditEditor
                              onChange={web_contentHandler}
                              value={moduledata?.web_content}
                              name="web_content"
                              id="web_content"
                              style={{ height: editorHeight }}
                              className="test"
                            />
                        </div>    
					 <div className="col-md-6 mt-5">
					   <label className="form-label" for="first_name">Image URL<span className="req-star">*</span></label>	
					   <input onChange={PicChange} id="tf-upload-img" accept="image/png, image/jpeg" type="file" name="profile" required="" className={validationError.imageError ? 'form-control is-invalid' : 'form-control'}  />	
					   <div className="invalid-feedback">{validationError.imageError}</div>
					   </div>	
					    <div className="col-md-6 mt-5">		  
					   <div className="sc-card-profile text-center is-invalid">
							<div className="card-media">
								{image_preview ?
									<img id="profileimg" src={image_preview ? image_preview : ''} alt="Slider image" style={{ width: '30%' }} />
									:
									!moduledata?.image_url || moduledata?.image_url == null || moduledata?.image_url == 'null' ?
										<img id="profileimg" src="images/noimage.png" alt="Slider image" style={{ width: '30%' }} />
										:
										<img id="profileimg" src={`${config.imageUrl + moduledata?.image_url}`} alt="Slider image" style={{ width: '30%' }} />
								}
							</div>
						</div>							
                        </div>                        
					  <div className="col-12 text-end pt-25">
						<button className="btn btn-primary" type="submit" onClick={UpdateModule} >Update</button>
					  </div>			  
				  </form>                
                </div>
              </div>
            </div>
          </div>          
        </div>
      </div>    
      <Footer />
      </div>
    </>
  )

}
export default EditModule;
