import React, { useEffect, useState } from 'react'
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import JoditEditor from "jodit-react";
import Modal from 'react-modal';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Sidebar from '../directives/sidebar'
import { Link } from 'react-router-dom';

import { getBranchesListAction,getDomainsListAction,getTopicListAction,createModuleAction, createTopicAction, getModulesTypesAction } from '../Action/action';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        zindex:'99999999999999999999'
    },
};


const CreateModule = () => {	
			
	const [BranchesList, setBranchesList] = useState([]);		
	const [branch_id, setbranch_id] = useState(null);		
	const [topic_id, settopic_id] = useState(null);		
	const [DomainsList, setDomainsList] = useState([]);	
	const [domain_id, setdomain_id] = useState(null);			
	const [TopicsList, setTopicsList] = useState([]);		
	const [FilterTopicsList, setFilterTopicsList] = useState([]);	
	const [moduletypeList, setmoduletypeList] = useState([]);	
	const [modalopen, setModalopen] = useState(0);	
	const [isDialogOpen, setDialogOpen] = useState(false);
	const [image_preview, setimage_preview] = useState('');
    const [image_file, setimage_file] = useState('');
	const editorHeight = '400px'; 
	useEffect(()=>{
		getBranchesListAPI();       
		getDomainsListAPI();       
		getTopicListAPI();   
		getModulesTypesAPI();    
    },[]);
	
	const getBranchesListAPI = async () => {
		let res = await getBranchesListAction();
		if (res.success) {
			setBranchesList(res.data);	
		}
	}
	const getDomainsListAPI = async () => {
		let res = await getDomainsListAction();
		if (res.success) {
			setDomainsList(res.data);	
		}
	}
	const getTopicListAPI = async () => {
		let res = await getTopicListAction();
		if (res.success) {
			setTopicsList(res.data);	
		}
	}
	
	const getModulesTypesAPI = async () => {
		let res = await getModulesTypesAction();
		if (res.success) {
			setmoduletypeList(res.data);	
		}
	}
	const [topicDetails, setTopicDetails] = useState({
       topic_title: '', topic_branch: '', topic_domain: ''    
    });

	const [form, setForm] = useState({ 
		title: '', 
		topic_id: '', 
		wrapper_app_description: '', 
		image_url: null, 
		video_url: '', 
		web_content: '', 
		vr_asset_id: 0, 
		web_asset_id: 0, 
		module_type_id: '', 
		is_active: '', 
		version: ''
	 });
	const [validationError, setvalidationError] = useState({});
	const [tpoicvalidationError, settopicvalidationError] = useState({});
	 const PicChange = async (e) => {
        e.preventDefault()
        let image_as_base64 = URL.createObjectURL(e.target.files[0])
        let image_as_files = e.target.files[0];
        let imageType = e.target.files[0].type;
        if (imageType == 'image/jpeg' || imageType == 'image/jpg' || imageType == 'image/png') {
            setimage_file(image_as_files);
            setimage_preview(image_as_base64);
            setForm((old) => {
                return { ...old, ['image_url']: image_as_files }
            })
        } else {
            toast.error('File type wrong please select JPG, JPEG or PNG.');
        }
    }
	const inputHandler = async (e) => {
		const { name, value } = e.target
		setForm((old) => {
			return { ...old, [name]: value }
		})
	}
	
	const inputTopicHandler = async (e) => {
		const { name, value } = e.target
		settopic_id(e.target.value);
		setForm((old) => {
			return { ...old, ['topic_id']: value }
		})
	}
	
	const SetTopicHandler = async (e) => {
		const { name, value } = e.target
		setTopicDetails((old) => {
			return { ...old, [name]: value }
		})
	}

	const app_descriptionHandler = async (e) => {
		setForm((old) => {
		  return { ...old, 'wrapper_app_description': e }
		})
	  }
	const web_contentHandler = async (e) => {
		setForm((old) => {
		  return { ...old, 'web_content': e }
		})
	  }
	  
	const handleKeyPress = (e) => {
		// Allow only numeric values (0-9) and certain control keys
		const isValidInput = /^[0-9\b]+$/.test(e.key);

		if (!isValidInput) {
		  e.preventDefault();
		}
	  };  
	
	const filterBranchHandler = async (e) => {
	 setbranch_id(e.target.value);
	 let searchBranch = e.target.value; 
	 if(searchBranch != null && domain_id !=null){
		 console.log(TopicsList);
		const result = TopicsList.filter(data => String(data.branch_id).includes(searchBranch) && String(data.domain_id).includes(domain_id)); 
		setFilterTopicsList(result); 		
	  } 
		
	}
	const filterDomainHandler = async (e) => {
		 setdomain_id(e.target.value); 		 
		 let searchDomain = e.target.value; 
		 if(searchDomain != null && branch_id !=null){
			  console.log(TopicsList);
			const result = TopicsList.filter(data => String(data.branch_id).includes(branch_id) && String(data.domain_id).includes(searchDomain)); 
			setFilterTopicsList(result); 		
		  }
	}
	const UpdateFilterTopics = async (domain,branch) => {
		 if(domain != null && branch !=null){
			let res = await getTopicListAction();
			if (res.success) {				
				const result = res.data.filter(data => String(data.branch_id).includes(branch) && String(data.domain_id).includes(domain)); 
				setFilterTopicsList(result); 		
			}
	  }
	}
   const closebutton = async () => {
        setModalopen(0);
    }
    
   function afterOpenModal() {
        //subtitle.style.color = '#f00';
    }
    
   function validate() {
		let titleError = "";
		let topic_idError = "";
		let branch_idError = "";
		let domain_idError = "";
		let is_activeError = "";
		let versionError = "";
		let imageError = "";
		let module_type_idError="";
				
		if (form.title === '') {
		  titleError = "Title is required."
		}
		if (branch_id === null) {
			branch_idError = "Branch is required."
		}
		if (domain_id === null) {
			domain_idError = "Course is required."
		}
		if (form.topic_id === '') {
			topic_idError = "Topic is required."
		}
		if (form.is_active === '') {
			is_activeError = "Status is required."
		}
		if (form.version === '') {
			versionError = "Vesrion is required."
		}
		if (form.module_type_id === '') {
			module_type_idError = "Module type is required."
		}
		if (form.image_url == null) {
            imageError = "Image field is required."
        }  
		console.log(form.image_url );		
		console.log(imageError );		
		if (titleError || topic_idError || is_activeError || versionError || imageError || module_type_idError || branch_idError || domain_idError) {
			setvalidationError({
			  titleError, topic_idError, is_activeError, versionError, imageError, module_type_idError,branch_idError, domain_idError
			})
			return false
		} else {
			setvalidationError({
			  titleError, topic_idError, is_activeError, versionError, imageError, module_type_idError, branch_idError, domain_idError
			})
			return true
		}
	}
	
   const SubmitForm = async (e) => {
		e.preventDefault()
		const isValid = validate();
		if (!isValid) {

		}
		else {
			let res = await createModuleAction(form);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}modules`;
				}, 1200);
			} else {
				toast.error(res.msg);
			}
		}
	}
   const AddtopicPop = () => {
	    let titleError = "";
		let branchError = "";
		let domainError = "";
	   setTopicDetails({topic_title: '', topic_branch: '', topic_domain: ''});	 
	   settopicvalidationError({
			  titleError, branchError, domainError
			})  
	   setModalopen(1);        
    }
  
   function validateTpoic() {
		let titleError = "";
		let branchError = "";
		let domainError = "";
		
		
		if (topicDetails.topic_title === '') {
		  titleError = "Title is required."
		}
		if (topicDetails.topic_branch === '') {
			branchError = "Branch is required."
		}
		if (topicDetails.topic_domain === '') {
			domainError = "Course is required."
		}
		
				
		if (titleError || branchError || domainError) {
			settopicvalidationError({
			  titleError, branchError, domainError
			})
			return false
		} else {
			settopicvalidationError({
			  titleError, branchError, domainError
			})
			return true
		}
	}
	
   const SubmitTopicForm = async (e) => {
		e.preventDefault()
		const isValid = validateTpoic();
		if (!isValid) {

		}
		else {
			let res = await createTopicAction({title:topicDetails.topic_title, branch_id:topicDetails.topic_branch, domain_id:topicDetails.topic_domain });
			if (res.success) {
				toast.success(res.msg);
				getTopicListAPI();
				closebutton();
				setbranch_id(topicDetails.topic_branch);
				setdomain_id(topicDetails.topic_domain);
				settopic_id(res.data);	
				UpdateFilterTopics(topicDetails.topic_domain,topicDetails.topic_branch);
				setForm((old) => {
				  return { ...old, 'topic_id': res.data }
				})				
				
			} else {
				toast.error(res.msg);
			}
		}
	}
   		
  return (

    <>
      <Sidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <Toaster />
         <Modal
                isOpen={isDialogOpen}
                onAfterOpen={afterOpenModal}
                style={customStyles}
                contentLabel="Example Modal">
                <div className="text-center pl-3 pr-3">
                    < br />
                    <h4 style={{ color: '#d71e5b', fontSize: '16px' }}>
                           Topic creation in progress
                     </h4>
                    <p style={{ color: '#091f3f' }}>
                        Please do not refresh the page or close the tab.
                    </p>
                    <div>
                        <img src="images/loader.gif" height={50} width={50} />
                    </div>
                </div>
            </Modal>
        <Header />
         <div className="container-lg mb-4">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-0 ms-2">
              <li className="breadcrumb-item">
               <span><a className="homelink" href={`${config.baseUrl}users`} >Home</a></span>
              </li>
              <li className="breadcrumb-item active"><span>Create Module</span></li>
            </ol>
          </nav>
        </div>
        <div className="body flex-grow-1 px-3">
        <div className="container-lg">
          <div className="row">
            
          <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header">
					<div className="row align-items-center">				
						<div className="col-md-6 text-start">
						 <span>Create Module</span>
						</div>
						<div className="col-md-6 text-end">
							<a className="btn btn-primary editbtn" href={`${config.baseUrl}modules`} >Back</a>
						</div>
					</div>
                </div>
                <div className="card-body inner-body">                
                 <form className="row g-3">
                  <div className="col-md-6">
						<label className="form-label" for="domain_id">Course<span className="req-star">*</span></label>
						<select className={validationError.domain_idError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="domain_id" name="domain_id" onChange={filterDomainHandler} value={domain_id}>
                              <option value="">Select Course</option>
								{
									DomainsList.map((item,index)=>{
										return(
											<option value={item.id}>{item.title}</option>
										)
									})
								}
                            </select>
                            <div className="invalid-feedback">{validationError.domain_idError}</div>
					  </div>
                  <div className="col-md-6">
						<label className="form-label" for="branch_id">Branch<span className="req-star">*</span></label>
						<select className={validationError.branch_idError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="branch_id" name="branch_id" onChange={filterBranchHandler} value={branch_id}>
                              <option value="">Select Branch</option>
								{
									BranchesList.map((item)=>{
										return(
											<option value={item.id}>{item.title}</option>
										)
									})
								}
                            </select>    
                             <div className="invalid-feedback">{validationError.branch_idError}</div>                       
					  </div>
					  
					  
					  
					   <div className="col-md-6">
					   <label className="form-label" for="branch_id">Topic<span className="req-star">*</span></label>
					   <div className={validationError.topic_idError ? 'mod-add-topic is-invalid' : 'mod-add-topic'}>						
						<select className='form-control' aria-label="select example" id="topic_id" name="topic_id" onChange={inputTopicHandler} value={topic_id}>
                              <option value="">Select Topic</option>
								{
									FilterTopicsList.map((item,index)=>{
										return(
											<option value={item.id}>{item.title}</option>
										)
									})
								}
                            </select>
                            <a href="javascript:;" className="btn btn-info" onClick={() => { AddtopicPop() }}>Addtopic</a>
                            </div>
                            <div className="invalid-feedback">{validationError.topic_idError}</div>
					  </div>
					 
					  <div className="col-md-6">
						<label className="form-label" for="first_name">Title<span className="req-star">*</span></label>
						<input className={validationError.titleError ? 'form-control is-invalid' : 'form-control'} id="title" name="title" type="text" placeholder="Enter module title" onChange={inputHandler} maxLength={30}/>
						<div className="invalid-feedback">{validationError.titleError}</div>
					  </div>
					  
					  <div className="col-md-6">
						<label className="form-label" for="first_name">Version<span className="req-star">*</span></label>
						<input className={validationError.versionError ? 'form-control is-invalid' : 'form-control'} id="version" name="version" placeholder="Enter version" type="text" onChange={inputHandler} onKeyPress={handleKeyPress} />	
						<div className="invalid-feedback">{validationError.versionError}</div>				
					  </div>					 
					  
					  <div className="col-md-6">
						<label className="form-label" for="first_name">Video URL</label>
						<input className='form-control' id="video_url" name="video_url" placeholder="Enter video URL" type="text" onChange={inputHandler} maxLength={30}/>						
					  </div>
					   <div className="col-md-6">
						<label className="form-label" for="module_type_id">Module Type<span className="req-star">*</span></label>
						<select className={validationError.module_type_idError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="module_type_id" name="module_type_id" onChange={inputHandler}>
                              <option value="">Select Module Type</option>
								{
									moduletypeList.map((item)=>{
										return(
											<option value={item.id}>{item.title}</option>
										)
									})
								}
                            </select>   
                            <div className="invalid-feedback">{validationError.module_type_idError}</div>                        
					  </div>	
					   <div className="col-md-6">
						<label className="form-label" for="is_active">Status<span className="req-star">*</span></label>
						<select className={validationError.is_activeError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="is_active" name="is_active" onChange={inputHandler}>
                              <option value="">Select Status</option>								
                              <option value="1">Active</option>								
                              <option value="0">In-Active</option>								
                            </select>
                            <div className="invalid-feedback">{validationError.is_activeError}</div>
					  </div>
					  <div className="col-md-12 mt-5">
					   <label className="form-label description-label" for="first_name">Wrapper app description</label>
					   <JoditEditor
                              onChange={app_descriptionHandler}
                              value=''
                              name="wrapper_app_description"
                              id="wrapper_app_description"
                              style={{ height: editorHeight }}
                              className="test"
                            />
                       </div>     
                     <div className="col-md-12 mt-5">
					   <label className="form-label description-label" for="first_name">Web portal description</label>       
					 <JoditEditor
                              onChange={web_contentHandler}
                              value=''
                              name="web_content"
                              id="web_content"
                              style={{ height: editorHeight }}
                              />
                           
                       </div>
					   <div className="col-md-6 mt-5">
					   <label className="form-label" for="first_name">Image URL<span className="req-star">*</span></label>					  
						<input onChange={PicChange} id="tf-upload-img" accept="image/png, image/jpeg" type="file" name="tf-upload-img" required=""  className={validationError.imageError ? 'form-control is-invalid' : 'form-control'} /> 
						<div className="invalid-feedback">{validationError.imageError}</div>						
                        	
					  </div> 
					  
					  <div className="col-12 text-end pt-25">
						<button className="btn btn-primary" type="submit" onClick={SubmitForm} >Save</button>
					  </div>
					  					  
				  </form>                
                </div>
              </div>
            </div>
          </div>          
        </div> 
      </div>
       <div className={modalopen == '0' ? "modal fade cart-modal mc-popup" : "mc-popup modal fade cart-modal show"} role="dialog" style={{ background: '0% 0% / cover rgba(6, 6, 6, 0.32)', display: modalopen == '0' ? 'none' : 'block' }}  id="myModal" tabindex="-1" data-backdrop="false">
		  <div className="modal-dialog modal-dialog-centered modal-lg">
			<div className="modal-content">
			  <div className="modal-header">				
				<button className="btn-close" type="button" onClick={closebutton} aria-label="Close"></button>
			  </div>
			  <div className="modal-body">
			  <div className="row align-items-center ">
				  <div className="col-md-5">
					<div className="topic-image text-center">
						<img src={`${config.baseUrl}images/modulepng.png`} />
				   </div>
			   </div>
			   <div className="col-md-7">
				<div className="row">
			   <div className="col-md-12">
						<label className="form-label" for="popdomain">Course<span className="req-star">*</span></label>
						<select className={tpoicvalidationError.domainError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="topic_domain" name="topic_domain" onChange={SetTopicHandler} value={topicDetails.topic_domain}>
                              <option value="">Select Course</option>
								{
									DomainsList.map((item,index)=>{
										return(
											<option value={item.id}>{item.title}</option>
										)
									})
								}
                            </select>   
                           <div className="invalid-feedback">{tpoicvalidationError.domainError}</div>                          
					  </div>
                  <div className="col-md-12 mt-3">
						<label className="form-label" for="popbranch">Branch<span className="req-star">*</span></label>
						<select className={tpoicvalidationError.branchError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="topic_branch" name="topic_branch" onChange={SetTopicHandler} value={topicDetails.topic_branch}>
                              <option value="">Select Branch</option>
								{
									BranchesList.map((item)=>{
										return(
											<option value={item.id}>{item.title}</option>
										)
									})
								}
                            </select>   
                           <div className="invalid-feedback">{tpoicvalidationError.branchError}</div>                         
					  </div>					 
					  
					  <div className="col-md-12 mt-3">
						<label className="form-label" for="first_name">Topic title<span className="req-star">*</span></label>
						<input className={tpoicvalidationError.titleError ? 'form-control is-invalid' : 'form-control'} id="topic_title" name="topic_title" type="text" onChange={SetTopicHandler} value={topicDetails.topic_title} maxLength={30} />
						 <div className="invalid-feedback">{tpoicvalidationError.titleError}</div>
					</div>
					<div className="col-md-6 pt-25 mt-3">
						<button className="btn btn-primary" type="button" onClick={SubmitTopicForm}>Create Topic</button>
					 </div>
					  
			  </div>
			  </div>
			  </div>			 
			</div>
		  </div>
		</div> 
		</div>
     
      <Footer />
      </div>
    </>
  )

}
export default CreateModule;
