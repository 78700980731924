import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  postRequestFormData,
} from "../coreFIles/helper"; 

export const LoginAction = (data) => {
  return postRequest("adminLogin", data).then((res) => {
    return res.data;
  });
};

export const getRoleslistAction = (data) => {
  return getRequest("getRoleslist", data).then((res) => {
    return res.data;
  });
};

export const getDomainsListAction = (data) => {
  return getRequest("getDomainsList", data).then((res) => {
    return res.data;
  });
};

export const getBranchesListAction = (data) => {
  return getRequest("getBrancheslist", data).then((res) => {
    return res.data;
  });
};

export const getCollegesListAction = (data) => {
  return getRequest("getCollegesList", data).then((res) => {
    return res.data;
  });
};

export const getUserslistAction = (data) => {
  return postRequest("getUserslist", data).then((res) => {
    return res.data;
  });
};
export const getTotalUsersListAction = (data) => {
  return postRequest("getTotalUsersList", data).then((res) => {
    return res.data;
  });
};

export const UserBlockUnBlockAction = (data) => {
  return postRequest("UserBlockUnBlock", data).then((res) => {
    return res.data;
  });
};

export const CreateUserAction = (data) => {
  return postRequest("createUser", data).then((res) => {
    return res.data;
  });
};

export const UserDeleteAction = (data) => {
  return postRequest("UserDelete", data).then((res) => {
    return res.data;
  });
};

export const getUserDetailsAction = (data) => {
  return postRequest("getUserDetails", data).then((res) => {
    return res.data;
  });
};

export const UpdateUserProfileAction = (data) => {
  return postRequest("UpdateUserProfile", data).then((res) => {
    return res.data;
  });
};

export const UpdateUserPasswordAction = (data) => {
  return postRequest("UpdateUserPassword", data).then((res) => {
    return res.data;
  });
};



export const getTopicListAction = (data) => {
  return getRequest("getTopicList", data).then((res) => {
    return res.data;
  });
};

export const createTopicAction = (data) => {
  return postRequest("createTopic", data).then((res) => {
    return res.data;
  });
};

export const deleteTopicAction = (data) => {
  return postRequest("deleteTopic", data).then((res) => {
    return res.data;
  });
};


export const getTopicDetailsAction = (data) => {
  return postRequest("getTopicDetails", data).then((res) => {
    return res.data;
  });
};

export const updateTopicAction = (data) => {
  return postRequest("updateTopic", data).then((res) => {
    return res.data;
  });
};

// MODULES 

export const getModulesListAction = (data) => {
  return postRequest("getModulesList", data).then((res) => {
    return res.data;
  });
};
export const getActiveModulesListAction = (data) => {
  return postRequest("getActiveModulesList", data).then((res) => {
    return res.data;
  });
};

export const getModuleDetailsAction = (data) => {
  return postRequest("getModuleDetails", data).then((res) => {
    return res.data;
  });
};

export const getTotalModulesListAction = (data) => {
  return postRequest("getTotalModulesList", data).then((res) => {
    return res.data;
  });
};

export const getTotalActiveModulesListAction = (data) => {
  return postRequest("getTotalActiveModulesList", data).then((res) => {
    return res.data;
  });
};

export const ModuleBlockUnBlockAction = (data) => {
  return postRequest("ModuleBlockUnBlock", data).then((res) => {
    return res.data;
  });
};

export const deleteModuleAction = (data) => {
  return postRequest("deleteModule", data).then((res) => {
    return res.data;
  });
};

export const createModuleAction = (data) => {
  return postRequestFormData("createModule", data).then((res) => {
    return res.data;
  });
};

export const updateModuleAction = (data) => {
  return postRequestFormData("updateModule", data).then((res) => {
    return res.data;
  });
};



// VR Assets

export const getVRAssetLatestVersionAction = (data) => {
  return postRequest("getVRAssetLatestVersion", data).then((res) => {
    return res.data;
  });
};

export const getVRassetDetailsAction = (data) => {
  return postRequest("getVRassetDetails", data).then((res) => {
    return res.data;
  });
};
export const getModuleVRassetsAction = (data) => {
  return postRequest("getModuleVRassets", data).then((res) => {
    return res.data;
  });
};
export const getModulesTypesAction = (data) => {
  return postRequest("getModulesTypes", data).then((res) => {
    return res.data;
  });
};

export const createVRassetAction = (data) => {
  return postRequestFormData("createVRasset", data).then((res) => {
    return res.data;
  });
};

export const approveVRassetAction = (data) => {
  return postRequest("approveVRasset", data).then((res) => {
    return res.data;
  });
};

// web assets
export const getWebassetDetailsAction = (data) => {
  return postRequest("getWebassetDetails", data).then((res) => {
    return res.data;
  });
};
export const getWebAssetLatestVersionAction = (data) => {
  return postRequest("getWebAssetLatestVersion", data).then((res) => {
    return res.data;
  });
};
export const getModuleWebassetsAction = (data) => {
  return postRequest("getModuleWebassets", data).then((res) => {
    return res.data;
  });
};

export const createWebassetAction = (data) => {
  return postRequestFormData("createWebasset", data).then((res) => {
    return res.data;
  });
};

export const approveWebassetAction = (data) => {
  return postRequest("approveWebasset", data).then((res) => {
    return res.data;
  });
};


// packages
export const createPackageAction = (data) => {
  return postRequest("createPackage", data).then((res) => {
    return res.data;
  });
};

export const createPackageModulesAction = (data) => {
  return postRequest("createPackageModules", data).then((res) => {
    return res.data;
  });
};

export const updatePackageModulesAction = (data) => {
  return postRequest("updatePackageModules", data).then((res) => {
    return res.data;
  });
};

export const getPackageDetailsAction = (data) => {
  return postRequest("getPackageDetails", data).then((res) => {
    return res.data;
  });
};

export const updatePackageAction = (data) => {
  return postRequest("updatePackage", data).then((res) => {
    return res.data;
  });
};
export const getActivePackagesListAction = (data) => {
  return postRequest("getActivePackagesList", data).then((res) => {
    return res.data;
  });
};
export const getActivePackagesListForLicenseAction = (data) => {
  return postRequest("getActivePackagesListForLicense", data).then((res) => {
    return res.data;
  });
};
export const getPackagesListAction = (data) => {
  return postRequest("getPackagesList", data).then((res) => {
    return res.data;
  });
};
export const getTotalPackagesListAction = (data) => {
  return postRequest("getTotalPackagesList", data).then((res) => {
    return res.data;
  });
};
export const PackageBlockUnBlockAction = (data) => {
  return postRequest("PackageBlockUnBlock", data).then((res) => {
    return res.data;
  });
};

export const deletePackageAction = (data) => {
  return postRequest("deletePackage", data).then((res) => {
    return res.data;
  });
};
export const getPackageModulesListAction = (data) => {
  return postRequest("getPackageModulesList", data).then((res) => {
    return res.data;
  });
};

export const packagemodulesdataAction = (data) => {
  return postRequest("packagemodulesdata", data).then((res) => {
    return res.data;
  });
};

export const getpackagelistbranchwiseAction = (data) => {
  return postRequest("getpackagelistbranchwise", data).then((res) => {
    return res.data;
  });
};

// Licenses
export const generateLicenseKeyAction = (data) => {
  return postRequest("generateLicenseKey", data).then((res) => {
    return res.data;
  });
};
export const getLicensesListAction = (data) => {
  return postRequest("getLicensesList", data).then((res) => {
    return res.data;
  });
};
export const getTotalLicensesListAction = (data) => {
  return postRequest("getTotalLicensesList", data).then((res) => {
    return res.data;
  });
};
export const LicenseBlockUnBlockAction = (data) => {
  return postRequest("LicenseBlockUnBlock", data).then((res) => {
    return res.data;
  });
};
export const deleteLicenseAction = (data) => {
  return postRequest("deleteLicense", data).then((res) => {
    return res.data;
  });
};

export const createLicenseAction = (data) => {
  return postRequest("createLicense", data).then((res) => {
    return res.data;
  });
};
export const getLicenseDetailsAction = (data) => {
  return postRequest("getLicenseDetails", data).then((res) => {
    return res.data;
  });
};
export const updateLicenseAction = (data) => {
  return postRequest("updateLicense", data).then((res) => {
    return res.data;
  });
};

// ModulesDescription
export const getModulesDescriptionListAction = (data) => {
  return postRequest("getModulesDescriptionList", data).then((res) => {
    return res.data;
  });
};
export const createModuleDescriptionAction = (data) => {
  return postRequest("createModuleDescription", data).then((res) => {
    return res.data;
  });
};
export const updateModuleDescriptionAction = (data) => {
  return postRequest("updateModuleDescription", data).then((res) => {
    return res.data;
  });
};
export const getModuleDescriptionDetailsAction = (data) => {
  return postRequest("getModuleDescriptionDetails", data).then((res) => {
    return res.data;
  });
};
export const ModulesDescriptionBlockUnBlockAction = (data) => {
  return postRequest("ModulesDescriptionBlockUnBlock", data).then((res) => {
    return res.data;
  });
};
export const deleteModulesDescriptionAction = (data) => {
  return postRequest("deleteModulesDescription", data).then((res) => {
    return res.data;
  });
};
