import React, { useEffect, useState } from 'react'
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import Pagination from './Pagination';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Sidebar from '../directives/sidebar'
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import {getLicensesListAction, getTotalLicensesListAction, LicenseBlockUnBlockAction, deleteLicenseAction, createLicenseAction, updateLicenseAction, getLicenseDetailsAction,getActivePackagesListForLicenseAction,generateLicenseKeyAction } from '../Action/action';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        zindex:'99999999999999999999'
    },
};

const Licenses = () => {

	const [LicensesList, setLicensesList] 			= useState([]);	  
	const [ActivePackagesList, setActivePackagesList] 			= useState([]);	  
	const [FilterPackagesList, setFilterPackagesList] 			= useState([]);	  
	const [page, setPage] 							= useState(1); 
	const [currentDate, setcurrentDate] 			= useState(new Date());
	const [totalPages, settotalPages] 				= useState(0);
	const [totalRecords, settotalRecords] 			= useState(0);
	const [pagingspinloader, setpagingspinloader] 	= useState(1);  
	const [titleFilter, SettitleFilter] 			= useState('');	
	const [titletypeFilter, SettypeFilter] 			= useState(1);	
	const [enddateFilter, SetenddateFilter] 		= useState('');	
	const [validationError, setvalidationError] 	= useState({});	
    const [modalopen, setModalopen] 				= useState(0);	
    const [editmodalopen, seteditmodalopen] 		= useState(0);	
	const [isDialogOpen, setDialogOpen] 			= useState(false);
	const [selectedOption, setSelectedOption] = useState(null);

	const handleChange = (selectedOption) => {
		setSelectedOption(selectedOption);
		setLicenseDetails((old) => {
				return { ...old, ['package_id']: selectedOption.value }
			})
	  };
	const [licenseDetails, setLicenseDetails] 		= useState({
       license_key: '', package_id: '', activation_rule: 0 , device_allowed: 1, expiry_date :''
    });
	
	useEffect(()=>{
	    setpagingspinloader(1);	   
		getTotalLicensesListAPI();
		getLicensesListAPI();
		getActivePackagesListAPI();
    },[]);
	const getTotalLicensesListAPI = async () => {
        let res = await getTotalLicensesListAction({title:titleFilter, titletype:titletypeFilter,enddate:enddateFilter });
        if (res.success) {
            settotalPages(res.data);
            settotalRecords(res.totalRecords);
            setpagingspinloader(0);            
		}
        else
        {
			settotalPages(0);
			settotalRecords(0);		
			setpagingspinloader(0);	
		}
		
		
    }
	
	const getLicensesListAPI = async () => {
		let res = await getLicensesListAction({title:titleFilter, titletype:titletypeFilter,enddate:enddateFilter, page:page});
		if (res.success) {      
		  setLicensesList(res.data);
		}
		else
		{
			setLicensesList(res.data);
		}
	  };
	  
	const getActivePackagesListAPI = async () => {
		let res = await getActivePackagesListForLicenseAction();
		if (res.success) {      
		  setActivePackagesList(res.data);
		  setFilterPackagesList(res.data);
		}
	  };
	const onPageChanged = async (data)  => {
		const { currentPage, totalPages, pageLimit } = data;
		const offset = (currentPage - 1) * pageLimit;
		let res = await getLicensesListAction({title:titleFilter, titletype:titletypeFilter,enddate:enddateFilter, page:offset});
        if (res.success) {
            setLicensesList(res.data);
        }
        else
        {
			setLicensesList({});	
		}

  }	
	
	const deleteLicense = async (id) => {
    Swal.fire({
		html: `<div>
				  <h4>Warning</h4>
				  <p>You want to Delete this license!</p>
				  <div>
					<img src="${config.baseUrl}images/warning.png" alt="Warning Image" />
				  </div>
				</div>
			  `,     
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Deleted it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteLicenseAction({ id: id });
        if (res.success) {
          getLicensesListAPI();   
           toast.success("License deleted successfully!");   
         
        } else {
           toast.error(res.msg);         
        }
      }
    });
  };
	const handleStatus = async (id, is_active) => {
        Swal.fire({
			 html: `
				<div>
				  <h4>Warning</h4>
				  <p>${is_active === 0 ? "Are you sure you want to disable this License?" : "Are you sure you want to enable this License?"}</p>
				  <div>
					<img src="${config.baseUrl}images/warning.png" alt="Warning Image" />
				  </div>
				</div>
			  `,         
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: is_active == 0 ? 'Yes, Disable it!' : 'Yes, Enable it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await await LicenseBlockUnBlockAction({ 'id': id, 'is_active': is_active });
                if (res.success) {
                    getLicensesListAPI();
                       if(is_active==0)
						toast.success("License disabled successfully!");
                    else
                     toast.success("License enabled successfully!");                    
                } else {
                    toast.error(res.msg); 
                }
            }
        })
    }
	const SettFilter = async (e) => {
        SettitleFilter(e.target.value);        
    }
	const SettyFilter = async (e) => {
        SettypeFilter(e.target.value);        
    }
	const FilterLicenses = async (e) => {
		setPage(1);	
        getTotalLicensesListAPI();
        getLicensesListAPI();       
    }
	const Clearlicenses = async (e) => {
	  SettitleFilter('');
	  SettypeFilter(1);
	  SetenddateFilter('');
	  setPage(1);	
      let res = await getTotalLicensesListAction({title:'', titletype:'',enddate:'' });
      if (res.success) {
            settotalPages(res.data);
            settotalRecords(res.totalRecords);
            setpagingspinloader(0);            
	 }
      else
      {
			settotalPages(0);
			settotalRecords(0);		
			setpagingspinloader(0);	
		}
      let list = await getLicensesListAction({title:'', titletype:'',enddate:'', page:1});
	  if (list.success) {
		setLicensesList(list.data);	
	  }
	  else
	  {
		setLicensesList([]);	
	  }      
    }
    const closebutton = async () => {
        setModalopen(0);
    } 
    function afterOpenModal() {
        //subtitle.style.color = '#f00';
    }
    const inputHandler = async (e) => {
		const { name, value } = e.target
		setLicenseDetails((old) => {
			return { ...old, [name]: value }
		})
	}
    function validate() {	
		let license_keyError = "";
		let package_idError = "";
		let activation_ruleError = "";		
		let device_allowedError = "";		
		let expiry_dateError = "";		
		if (licenseDetails.license_key === '') {
		  license_keyError = "License key is required."
		}
		if (licenseDetails.package_type === '') {
			package_idError = "Package is required."
		}
		if (licenseDetails.activation_rule === '') {
			activation_ruleError = "Rule is required."
		}	
		if (licenseDetails.device_allowed === '') {
			device_allowedError = "Device allowed is required."
		}	
		if (licenseDetails.expiry_date === '') {
			expiry_dateError = "Expire date is required."
		}	
		
				
		if (license_keyError || package_idError || activation_ruleError || device_allowedError || expiry_dateError) {
			setvalidationError({
			  license_keyError, package_idError, activation_ruleError,device_allowedError,expiry_dateError})
			return false
		} else {
			return true
		}
	}
	  
   const AddLicense = async (e) => {	   
	   let res = await generateLicenseKeyAction();
		if (res.success) {
			const value = res.data
			setLicenseDetails({ license_key: value, package_id: '', activation_rule: 0 , device_allowed: 1, expiry_date :''});
			 setModalopen(1); 
		}
    } 
   const SubmitLicense = async (e) => {
		e.preventDefault()
		const isValid = validate();
		if (!isValid) {

		}
		else {
			let res = await createLicenseAction(licenseDetails);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}licenses`;
				}, 1200);
				
			} else {
				toast.error(res.msg);
			}
		}
	}
   
   function formatDate(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    } 
   const handleChangeExpiryDate = e => {
        let expiryDate = formatDate(e);
       SetenddateFilter(expiryDate);
    }
    
   const handleExpiry_date = e => {
        let expiryDate = formatDate(e);
       setLicenseDetails((old) => {
				return { ...old, ['expiry_date']: expiryDate }
			})
    }
   const CreateLicenseKey = async (e) => {	
		let res = await generateLicenseKeyAction();
		if (res.success) {
			const value = res.data
			setLicenseDetails((old) => {
				return { ...old, ['license_key']: value }
			})
		} else {
			toast.error(res.msg);
		}		
	}
	
	const handleAdd = () => {
		setLicenseDetails((old) => {
				return { ...old, ['device_allowed']: (licenseDetails.device_allowed === '' ? 1 : parseInt(licenseDetails.device_allowed, 10) + 1)}
			})
	  };

	const handleSubtract = () => {
		const value =(licenseDetails.device_allowed === '' ? 1 : parseInt(licenseDetails.device_allowed, 10) - 1);
		
		setLicenseDetails((old) => {
				return { ...old, ['device_allowed']: value < 1 ? 1: value }
			})
	  };

	const handleInputChange = (e) => {
		const inputValue = e.target.value;

		// Allow only numeric input
		if (/^\d*$/.test(inputValue)) {		 
		  setLicenseDetails((old) => {
				return { ...old, ['device_allowed']: parseInt(inputValue, 10) }
			})
		}
	  };
    
  return (

    <>
      <Sidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <Toaster />
        <Header />       
         <div className="container-lg mb-4">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-0 ms-2">
              <li className="breadcrumb-item">
               <span><a className="homelink" href={`${config.baseUrl}users`} >Home</a></span>
              </li>
              <li className="breadcrumb-item active"><span>Licenses</span></li>
            </ol>
          </nav>
        </div>
        <div className="body flex-grow-1 px-3">
        <div className="container-lg">
          <div className="row">
            
          <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header">
					<div className="row align-items-center">				
						<div className="col-md-6 text-start">
						 <span>Licenses</span>
						</div>
						<div className="col-md-6 text-end">
						<a className="btn btn-danger createmodulebtn" href='javascript:;' onClick={() => { AddLicense() }} >Generate New Key <span><img src={`${config.baseUrl}images/key.png`} /></span> </a>
						
						</div>
					</div>
					<div className="row mb-4 mt-4 align-items-end">
						
						<div className="col-md-12 col-lg-12 col-xl-6 mt-3 searching">
						<label className="form-label">Search License key & Package Name</label>		
						  <div className="display-flex">								 
						  <input type="text" name="title" placeholder="Search..." id="input-name" data-oc-target="autocomplete-name" className="form-control search-license" autocomplete="off" onChange={SettFilter} value={titleFilter}/>
						  <select className='form-control custom-filter' aria-label="select example" id="titletype" name="titletype" onChange={SettyFilter} value={titletypeFilter}>
                             <option value="1">License Key</option>								
                              <option value="2">Package Title</option>								
                            </select>
                            </div>
						</div>
						<div className="col-lg-6 col-md-12 col-xl-2 mt-3">
						<label className="form-label">Expiry range</label>		
						<DatePicker onChange={handleChangeExpiryDate}  placeholder ="Select Date" autoComplete="off" name="end_date" id="end_dateError" className="form-control cleander-icon" value={enddateFilter} />
						
						</div>
						<div className="col-lg-6 col-xl-4 col-md-12 pt-25 mt-3">
						  <label className="form-label">&nbsp;</label>
						  <button type="button" id="button-filter" className="btn btn-info" onClick={() => FilterLicenses()}><i className="fa fa-search"></i> Search</button>
						  &nbsp;&nbsp;&nbsp;
						  <button type="button" id="button-filter" className="btn btn-primary" onClick={() => Clearlicenses()}>Clear</button>
						</div>
						
					</div>	
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead className="table-light fw-semibold">
                        <tr className="align-middle table-heading">
                          <th>SR.</th>
                          <th>Title</th>
                          <th>Devices</th>
                          <th>Expiry</th>
                          <th>Package</th>
                          <th>Status</th>
                          <th>Action</th>
                          <th>Rule</th>
                          <th>Created By</th>
                        </tr>
                      </thead>
                      <tbody>
                         { LicensesList.length> 0 ?
							LicensesList.map((item, index) => (
								<>
									<tr key={index}>
										<td>{index+1}</td>
										<td>{item.license_key}</td>										
										<td>{item.device_allowed}</td>										
										<td>{moment(item.expiry_date).format('DD/MM/YYYY')}</td>						
										<td>{item.package_title}</td>										
										<td>
											<div className="form-check form-switch">
											  <input
												className="form-check-input"
												id={`flexSwitchCheckChecked_${index}`}
												type="checkbox"
												checked={item.is_active === 1}
												onChange={() => handleStatus(item.id, item.is_active === 1 ? 0 : 1)}
											  />
											</div>
										</td>
										<td>										
											<button type="button" className="btn btn-danger deletebtn" onClick={() => deleteLicense(item.id)}><i className='fa fa-trash'></i></button>										  
										</td>
										<td>{item.activation_rule ==0 ? 'Default': '-'}</td>
										<td>{item.created_by_username}</td>
									</tr>
									</>
								)
							)
							:
							(
								<tr><td colspan="9" className="text-center">No license found.</td></tr>
							)					 
						}
                      
                      </tbody>
                    </table>
                     {totalPages > 0 ?										  
						 <Pagination totalRecords={totalRecords} totalPages={totalPages} pageNeighbours={page} onPageChanged={onPageChanged}   />
						 : ''
					 }	
                  </div>                
                </div>
              </div>
            </div>
          </div>          
        </div>
      </div> 
        <div className={modalopen == '0' ? "modal fade cart-modal mc-popup" : "mc-popup modal fade cart-modal show"} role="dialog" style={{ background: '0% 0% / cover rgba(6, 6, 6, 0.32)', display: modalopen == '0' ? 'none' : 'block' }}  id="myModal" tabindex="-1" data-backdrop="false">
		  <div className="modal-dialog modal-dialog-centered modal-lg">
			<div className="modal-content">
			  <div className="modal-header">				
				<button className="btn-close" type="button" onClick={closebutton} aria-label="Close"></button>
			  </div>
			  <div className="modal-body">
			  <div className="row">
			   <div className="col-md-4 gen-key-left">
				   <h4>Genrate New Key</h4>
				   <div className="key-image">
						<img src={`${config.baseUrl}images/genrate-link.png`} />
				   </div>
					   
			   
                </div>
                <div className="col-md-8">
					<div className="row">
						<div className="col-md-12 pb-4">
						<div className="display-flex ">
						<input className={validationError.license_keyError ? 'form-control is-invalid license-key' : 'form-control license-key'} id="license_key" name="license_key" type="text" placeholder="License key" onChange={inputHandler} value={licenseDetails.license_key} readonly="true" />&nbsp;
						<button className="btn btn-info createlic" type="button" onClick={CreateLicenseKey}>
						<i className="fa-solid fa-rotate-right"></i></button>
						</div>
						<div className="invalid-feedback">{validationError.license_keyError}</div>
                                                   
					  </div>
					 <div className="col-md-12 pb-4">
						 <Select
								className={validationError.package_id ? 'is-invalid package-list' : 'package-list'}
								value={selectedOption}
								onChange={handleChange}
								options={ActivePackagesList}
								isSearchable={true}
								placeholder="Select a package..."
								noOptionsMessage={() => "No package found"}
							  />							 
                            
					  </div>					 				
					  <div className="col-md-4 pb-4">
						<label className="form-label" for="activation_rule">Select Rule<span className="req-star">*</span></label>
						<select className={validationError.activation_ruleError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="activation_rule" name="activation_rule" onChange={inputHandler} value={licenseDetails.activation_rule}>
                              <option value="0">Default</option>								
                            </select>
                            <div className="invalid-feedback">{validationError.is_activeError}</div>
					  </div>
					  
					  <div className="col-md-4 pb-4">
					  <label className="form-label" for="activation_rule">Allowed Devices<span className="req-star">*</span></label>
						<div className="display-flex inc-dec">
							<button className="dec" onClick={handleSubtract}>-</button>
						<input
							type="text"
							className="form-control text-center device-allowed"
							value={licenseDetails.device_allowed}
							id="device_allowed"
							name="device_allowed"
							onChange={handleInputChange}							
						  />
						  <button className="inc" onClick={handleAdd}>+</button>
						  
						</div>
					  </div>
					   <div className="col-md-4 pb-4 exp-date">
						<label className="form-label">Expiry date</label>		
						<DatePicker onChange={handleExpiry_date}  placeholder ="Select Date" autoComplete="off" name="expiry_date" id="expiry_dateError" className={validationError.expiry_dateError ? 'form-control is-invalid' : 'form-control'} value={licenseDetails.expiry_date} minDate={currentDate}  />
					  </div>
					<div className="col-md-12 pt-25 text-start ">
						<button className="btn btn-primary" type="button" onClick={SubmitLicense}>Save</button>
					 </div>
				</div>	  
			  </div>
			  </div>			 
			  </div>
			</div>
		  </div>
		</div>
      <Footer />
      </div>
    </>
  )

}
export default Licenses;
