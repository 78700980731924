import React, { useEffect, useState } from 'react'
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Sidebar from '../directives/sidebar'
import { getTopicListAction, deleteTopicAction } from '../Action/action';

const Topics = () => {

	const [TopicList, setTopicList] = useState([]);

	const getTopicList = async () => {
		
		let res = await getTopicListAction();
		if (res.success) {
			let data = res.data;
			
			setTopicList(data);	
			
			console.log(TopicList);
		}
		else
		{
			setTopicList([]);	
		}
	}
      
   useEffect(()=>{
        getTopicList();
    },[]);
    
    
	const deleteTopic = async (id) => {
		Swal.fire({
			 html: `
				<div>
				  <h4>Warning</h4>
				  <p>You want to Delete this topic!</p>
				  <div>
					<img src="${config.baseUrl}images/warning.png" alt="Warning Image" />
				  </div>
				</div>
			  `,		
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: "Yes, Deleted it!",
		}).then(async (result) => {
			if (result.isConfirmed) {
				let res = await deleteTopicAction({'id':id});
				if (res.success) {
					toast.success(res.msg);
					getTopicList();
				} else {
					toast.error(res.msg);
				}
			}
		});
	}
   

  return (

    <>
      <Sidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        
        <Header />
         <div className="container-lg mb-4">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-0 ms-2">
              <li className="breadcrumb-item">
               <span><a className="homelink" href={`${config.baseUrl}users`} >Home</a></span>
              </li>
              <li className="breadcrumb-item active"><span>Topics</span></li>
            </ol>
          </nav>
        </div>
        <div className="body flex-grow-1 px-3">
        <div className="container-lg">
          <div className="row align-items-end">
            
          <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header">
					<div className="row align-items-center">				
						<div className="col-md-6 text-start">
						 <span>Topics</span>
						</div>
						<div className="col-md-6 text-end">
							<a className="btn btn-primary createbtn" href={`${config.baseUrl}createtopic`} ><i className="fa fa-plus"></i> Create topic</a>
						</div>
					</div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead className="table-light fw-semibold">
                        <tr className="align-middle table-heading">
                          <th>Sr.</th>
                          <th style={{ minWidth: '150px' }}>Topic</th>
                          <th>Course</th>
                          <th>Branch</th>
                          <th style={{ minWidth: '250px' }} className="text-right">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                       { TopicList.length> 0 ?
							TopicList.map((xtopic,index)=>{
								return(
									<tr key={index}>
										<td>{index+1}</td>
										<td>{xtopic.title}</td>
										<td>{xtopic.domain}</td>
										<td>{xtopic.branch}</td>
										<td className="text-right">
										<Link to={`${config.baseUrl}edittopic/` + xtopic.id}>
												<button className='btn btn-primary editbtn'><i className="far fa-edit"></i> Edit</button>
											</Link> &nbsp;
											&nbsp;&nbsp; <button type="button" className="btn btn-danger deletebtn" onClick={() => deleteTopic(xtopic.id)}><i className='fa fa-trash'></i> Delete</button>			  
										</td>
										
									</tr>
								)
							})
							
							:
							(
								<tr><td colspan="5" className="text-center">No topic found.</td></tr>
							)	
						}
                      
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>          
        </div>
      </div>    
      <Footer />
      </div>
    </>
  )

}
export default Topics;
