const config = {
  baseUrl: '/',
  //~ imageUrl: "http://localhost:3009/images",
  //~ apiUrl: "http://localhost:3009/api",
  imageUrl: "https://staging.ixrlabs.com/apis/api/uploads/",
  apiUrl: "https://staging.ixrlabs.com/apis/api",
  superAdmin:1,
  paging_size:50
}
export default config;
