import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import toast, { Toaster } from "react-hot-toast";
import config from "../coreFIles/config";
import Cookies from 'js-cookie';
import moment from 'moment';
import Swal from 'sweetalert2';
import Modal from 'react-modal';
import { getBranchesListAction, getDomainsListAction, getTopicListAction, getModuleDetailsAction, getModuleWebassetsAction,createWebassetAction, getModulesTypesAction, approveWebassetAction, getWebAssetLatestVersionAction, getWebassetDetailsAction } from "../Action/action";
import { Link, useParams } from 'react-router-dom';
const loginData = (!Cookies.get('loginSuccessIXRAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessIXRAdmin'));


const Webassets = () => {
	const { id } = useParams();
	const [WebassetsList, setWebassetsList] = useState([]);	  
	const [moduletypeList, setmoduletypeList] = useState([]);	  
	const [moduledata, setmoduledata] = useState({});	
	const [validationError, setvalidationError] = useState({});	
	const [image_preview, setimage_preview] = useState('');
    const [image_file, setimage_file] = useState('');	
    const [modalopen, setModalopen] = useState(0);	
    const [BranchesList, setBranchesList] = useState([]);		
	const [branch_id, setbranch_id] = useState(null);		
	const [DomainsList, setDomainsList] = useState([]);	
	const [domain_id, setdomain_id] = useState(null);			
	const [TopicsList, setTopicsList] = useState([]);	
	const [FilterTopicsList, setFilterTopicsList] = useState([]);
	const [spindetailloader, setspindetailloader] = useState(0);
	const editorHeight = '400px'; 
	const [form, setForm] = useState({ 
		module_id: id, 		
		web_asset_base_url: '', 
		thumnail_file_url: '', 
		index_file_url: '', 
		bundles_file_url: [], 
		version: '', 
		module_type_id: '', 
		uploaded_by_user_id: loginData?.id	
	 });
	 
	const [assetDetails, setassetDetails] = useState({ 		
		web_asset_base_url: '', 
		thumnail_file_url: '', 
		index_file_url: '', 
		bundles_file_url: [], 
		version: '', 		
		module_type_id: '', 		
		uploaded_by:''
	 }); 	
  
   useEffect(() => {	   
		getModulesTypesAPI();
		getBranchesListAPI();       
		getDomainsListAPI();       
		getTopicListAPI();
		getModuleDetailsAPI();        
		getModuleWebassetsAPI();    
		getWebAssetLatestVersionAPI();       
    }, [])
    
    const inputHandler = async (e) => {
		const { name, value } = e.target
		setForm((old) => {
			return { ...old, [name]: value }
		})
	}
	const getBranchesListAPI = async () => {
		let res = await getBranchesListAction();
		if (res.success) {
			setBranchesList(res.data);	
		}
	}
	const getDomainsListAPI = async () => {
		let res = await getDomainsListAction();
		if (res.success) {
			setDomainsList(res.data);	
		}
	}
	const getTopicListAPI = async () => {
		let res = await getTopicListAction();
		if (res.success) {
			setTopicsList(res.data);	
		}
	}
	const inputthumnailfileHandler = async (e) => {
        e.preventDefault()
        //~ let image_as_base64 = URL.createObjectURL(e.target.files[0])
        let image_as_files = e.target.files[0];
        let imageType = e.target.files[0].type;
        if (imageType == 'image/jpeg' || imageType == 'image/jpg' || imageType == 'image/png') {
            //~ setimage_file(image_as_files);
            //~ setimage_preview(image_as_base64);
            setForm((old) => {
                return { ...old, ['thumnail_file_url']: image_as_files }
            })
        } else {
            toast.error('File type wrong please select JPG, JPEG or PNG.');
        }
    }
	const inputindexfileHandler = async (e) => {
        e.preventDefault()
        //~ let image_as_base64 = URL.createObjectURL(e.target.files[0])
        let image_as_files = e.target.files[0];
        let imageType = e.target.files[0].type;
        if (imageType == 'image/jpeg' || imageType == 'image/jpg' || imageType == 'image/png') {
            //~ setimage_file(image_as_files);
            //~ setimage_preview(image_as_base64);
            setForm((old) => {
                return { ...old, ['index_file_url']: image_as_files }
            })
        } else {
            toast.error('File type wrong please select JPG, JPEG or PNG.');
        }
    }
    
    
    
    const inputbundlesfileHandler = (e) => {
		 e.preventDefault()
         const files = Array.from(e.target.files);
         let newFiles = [];
         
		 Array.from(files).forEach((file) => {
			newFiles.push(file);
		 });
             
		setForm((old) => {
			return { ...old, ['bundles_file_url'] :newFiles }
		})
	  };

    
	const getWebAssetLatestVersionAPI = async () => {
		let res = await getWebAssetLatestVersionAction({ 'module_id': id });
        if (res.success) {
          setForm((old) => {
			return { ...old, ['version']: res.data }
			})
           }
    } 
    const getModuleDetailsAPI = async () => {		
        let res = await getModuleDetailsAction({ 'id': id });
        if (res.success) {
            setmoduledata(res.data)    
            setFilterTopicsList(res.topics)
            setbranch_id(res.data.branch_id);	
			setdomain_id(res.data.domain_id); 
			
			setForm((old) => {
				
				return { ...old, ['module_type_id'] :res.data.module_type_id }
			})
			      
           }
    } 
    const getModuleWebassetsAPI = async () => {		
        let res = await getModuleWebassetsAction({ 'module_id': id });
        if (res.success) {
            setWebassetsList(res.data)
           }
    } 
	const getModulesTypesAPI = async () => {
		let res = await getModulesTypesAction();
		if (res.success) {
			setmoduletypeList(res.data);	
		}
	}
	
   function validate() {
		let versionError = "";
		let index_file_urlError = "";
		let thumnail_file_urlError = "";		
		let bundles_file_urlError = "";		
				
		if (form.version === '') {
		  versionError = "Version is required."
		}
		if (form.index_file_url === '') {
			index_file_urlError = "Index file is required."
		}	
		if (form.thumnail_file_url === '') {
			thumnail_file_urlError = "Thumnail file is required."
		}	
		if (form.bundles_file_url === '') {
			bundles_file_urlError = "Bundles file is required."
		}	
			
		if (versionError || index_file_urlError || thumnail_file_urlError || bundles_file_urlError) {
			setvalidationError({
			  versionError, index_file_urlError, thumnail_file_urlError, bundles_file_urlError
			})
			return false
		} else {
			setvalidationError({
			  versionError, index_file_urlError, thumnail_file_urlError, bundles_file_urlError
			})
			return true
		}
	}
	
	const SubmitForm = async (e) => {
		e.preventDefault()
		const isValid = validate();
		if (!isValid) {

		}
		else {
			let res = await createWebassetAction(form);
			if (res.success) {
				console.log(res.msg);
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}webassets/`+id;
				}, 1200);
			} else {
				toast.error(res.msg);
			}
		}
	}
	
	const handleAssetApproval = async (asset_id, is_approved) => {
        Swal.fire({
			html: `
				<div>
				  <h4>Warning</h4>
				  <p>${is_approved === 0 ? "You want to reject this Asset!" : "You want to approved this Asset!"}</p>
				  <div>
					<img src="${config.baseUrl}images/warning.png" alt="Warning Image" />
				  </div>
				</div>
			  `,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: is_approved == 0 ? 'Yes, reject it!' : 'Yes, approve it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await await approveWebassetAction({ 'id': asset_id, 'is_approved': is_approved });
                if (res.success) {
                    getModuleWebassetsAPI();
                    if(is_approved==0)
						toast.success("Asset rejected successfully!");
                    else
                     toast.success("Asset Aapproved successfully!");                   
                } else {
                     toast.error(res.msg);       
                }
            }
        })
    }
	
	const closebutton = async () => {
        setModalopen(0);
    }
    
   function afterOpenModal() {
        //subtitle.style.color = '#f00';
    }
    const viewAssetDetails  = async (item_id) => {
	    
	    let res = await getWebassetDetailsAction({ 'id': item_id });
        if (res.success) {
			setassetDetails(res.data);
			setModalopen(1);       
		} 
    }
 
  return (

    <>
      <Sidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
         <Toaster />
        <Header />
         <div className="container-lg mb-4">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-0 ms-2">
              <li className="breadcrumb-item">
               <span><a className="homelink" href={`${config.baseUrl}users`} >Home</a></span>
              </li>
              <li className="breadcrumb-item">
               <span><a href={`${config.baseUrl}assets-list`} >Assets</a></span>
              </li>
               <li className="breadcrumb-item">
               <span><a href={`${config.baseUrl}editmodule/`+id} >{moduledata?.title}</a></span>
              </li>
              <li className="breadcrumb-item active"><span>Web Assets</span></li>
            </ol>
          </nav>
        </div>
        <div className="body flex-grow-1 px-3">
        <div className="container-lg">
          <div className="row align-items-end">
            
          <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header">
					<div className="row align-items-center">				
						<div className="col-md-6 text-start">
						 <span>WebGL Assets</span>
						</div>
						<div className="col-md-6 text-end">
							<a className="btn btn-primary editbtn" href={`${config.baseUrl}assets-list`} >Back</a>
						</div>
					</div>
					 </div>
					 <div className="card-body inner-body"> 
					<div className="row g-3 evnt-disable">
					 <div className="col-md-6">
						<label className="form-label" for="domain_id">Course<span className="req-star">*</span></label>
						<select className="form-control evnt-disable" aria-label="select example" id="domain_id" name="domain_id" value={domain_id}>
                              <option value="">Select Course</option>
								{
									DomainsList.map((item,index)=>{
										return(
											<option value={item.id}>{item.title}</option>
										)
									})
								}
                            </select>
					  </div>
						<div className="col-md-6">
						<label className="form-label" for="branch_id">Branch<span className="req-star">*</span></label>
						<select className="form-control" aria-label="select example" id="branch_id" name="branch_id" value={branch_id}>
                              <option value="">Select Branch</option>
								{
									BranchesList.map((item)=>{
										return(
											<option value={item.id}>{item.title}</option>
										)
									})
								}
                            </select>                           
					  </div>
					    <div className="col-md-6">
						<label className="form-label" for="topic_id">Topic<span className="req-star">*</span></label>
						<select className='form-control' aria-label="select example" id="topic_id" name="topic_id" value={moduledata?.topic_id} >
                              <option value="">Select Topic</option>
							{spindetailloader == '0' ?	
									FilterTopicsList.map((item,index)=>{
										return(
											<option value={item.id}>{item.title}</option>
										)
									})								
								:
								'<i className="fa fa-spinner fa-spin validat"></i>'
							}
                            </select>
					  </div>
					 
					  <div className="col-md-6">
						<label className="form-label" for="title">Title<span className="req-star">*</span></label>
						<input className='form-control' id="title" name="title" type="text" value={moduledata?.title} maxLength={30} />
					  </div>
					  
					  <div className="col-md-6">
						<label className="form-label" for="version">Version<span className="req-star">*</span></label>
						<input className='form-control' id="version" name="version" type="text" onChange={inputHandler} value={moduledata?.version} />
					  </div>
					  <div className="col-md-6">
						<label className="form-label" for="module_type_id">Module Type<span className="req-star">*</span></label>
						<select className='form-control' aria-label="select example" id="module_type_id" name="module_type_id" value={moduledata?.module_type_id}>
                              <option value="">Select Module Type</option>
								{
									moduletypeList.map((item)=>{
										return(
											<option value={item.id}>{item.title}</option>
										)
									})
								}
                            </select>   
                        </div>	
					  </div>	
					  </div>
					   <div className="col-md-12 mt-5 mb-3 ml-30">
					   <label className="form-label description-label" for="first_name">Upload Assets</label>					  
					   </div> 
					 
					 <div className="card-body form-body"> 
					 <div className="row mb-4 align-items-end">
						  <div className="col-md-6 mb-2">
							<label className="form-label" for="first_name">Index file URL<span className="req-star">*</span></label>												
							<input className={validationError.web_asset_base_urlError ? 'form-control is-invalid' : 'form-control'} onChange={inputindexfileHandler} id="index_file_url" className="form-control" accept="image/png, image/jpeg" type="file" name="index_file_url" required="" />
							
							<span className="validationErr">{validationError.index_file_urlError}</span>
							
						  </div>	
						    
						  <div className="col-md-6 mb-2">
							<label className="form-label" for="first_name">Thumnail<span className="req-star">*</span></label>												
							<input className={validationError.thumnail_file_urlError ? 'form-control is-invalid' : 'form-control'} onChange={inputthumnailfileHandler} id="thumnail_file_url" className="form-control" accept="image/png, image/jpeg" type="file" name="thumnail_file_url" required="" />
							
							<span className="validationErr">{validationError.index_file_urlError}</span>
							
						  </div>
						    
						  <div className="col-md-6 mb-2">
							<label className="form-label" for="first_name">Bundle files<span className="req-star">*</span></label>												
							<input className={validationError.bundles_file_urlError ? 'form-control is-invalid' : 'form-control'} onChange={inputbundlesfileHandler} id="bundles_file_url" className="form-control" accept="image/png, image/jpeg" type="file" name="bundles_file_url" required="" multiple />
							
							<span className="validationErr">{validationError.bundles_file_urlError}</span>
							
						  </div>
						  
						 
						  
						  
						  
					  </div>
					
                  </div>
                  
                  
                  
                 <div className="row align-items-center mb-4 mt-5">				
						 <div className="col-12 text-center">
							<button className="btn btn-primary" type="submit" onClick={SubmitForm} >Update</button>
						  </div>
			   </div>
                <div className="card-body">
					<div className="col-md-12 text-start">
						 <span className="ml-20">WebGL Assets Updates Activities</span>
						</div>	
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead className="table-light fw-semibold">
                        <tr className="align-middle table-heading">
                          <th>Sr.</th>                         
                          <th>Module</th>
                          <th>Uploaded By</th>
                          <th>Uploaded On</th>
                          <th>Version</th>
                          <th>Approved by</th>
                          <th>Approved On</th>
                          <th>Status</th>                          
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                         { WebassetsList.length> 0 ?
							WebassetsList.map((item, index) => (
								<>
									<tr key={index}>
										<td>{index+1}</td>
										<td><a href="javascript:;" onClick={() => { viewAssetDetails(item.id) }}>{item.module_title}</a></td>
										<td>{item.uploadedby}</td>
										<td>{moment(item.created_on).format('DD/MM/YYYY')}</td>
										<td>{item.version}</td>
										<td>{item.approved_by_user_id !=0 ? item.approvedby:'-'}</td>
										<td>{item.approved_by_user_id !=0 ? moment(item.approved_on).format('DD/MM/YYYY') :'-'}</td>
										<td>{item.is_approved ==1 ? 'Approved':'Pending for approval' }</td>
										<td>										
											<button className={item.is_approved ==1 ? 'btn btn-danger deletebtn':'btn btn-success deletebtn' } type="submit" onClick={() => handleAssetApproval(item.id, item.is_approved === 1 ? 0 : 1)} >
											{item.is_approved ==1 ? 'Reject':'Approve' }
											</button>
										 	
																			  
										</td>
										
									</tr>
									</>
								)
							)
							:
							<>
									<tr>
										<td colspan="9">No record found</td>
									</tr>
							</>				 
						}
                      
                      </tbody>
                    </table>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>          
        </div>
      </div>   
      
      <div className={modalopen == '0' ? "modal fade cart-modal mc-popup" : "mc-popup modal fade cart-modal show"} role="dialog" style={{ background: '0% 0% / cover rgba(6, 6, 6, 0.32)', display: modalopen == '0' ? 'none' : 'block' }}  id="myModal" tabindex="-1" data-backdrop="false">
		  <div className="modal-dialog modal-dialog-centered modal-lg">
			<div className="modal-content">
			  <div className="modal-header">				
				<button className="btn-close" type="button" onClick={closebutton} aria-label="Close"></button>
			  </div>
			  <div className="modal-body">
				<div className="row mb-4">
				 <div className="table-responsive">
                    <table className="table mb-0">
                      <thead className="table-light fw-semibold">
                        <tr className="align-middle table-heading">                                            
                          <th>Version</th>
                          <th>Index file URL</th>
                          <th>Thumnail</th>                                                 
                          <th>Uploaded by</th>
                        </tr>
                      </thead>
                      <tbody>
                       <tr>						
							<td>{assetDetails.version}</td>
							<td>{assetDetails.web_asset_base_url}</td>
							<td>{assetDetails.thumnail_file_url ?
							<img id="profileimg" src={`${assetDetails?.thumnail_file_url}`} alt="Slider image" style={{ width: '100px' }} />
							:''	}
							</td>
							<td>{assetDetails.uploaded_by}</td>
						</tr>
                      </tbody>
                    </table>
                    
                  </div>
				</div>
			  </div>			 
			</div>
		  </div>
		</div> 
      <Footer />
      </div>
    </>
  )

};
export default Webassets;
