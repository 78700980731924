import React, { useEffect, useState } from 'react'
import Header from '../directives/header'
import Footer from '../directives/footer'
import Sidebar from '../directives/sidebar'

const Dashboard = () => {


  return (

    <>
      <Sidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        
        <Header />
        
        <div className="body flex-grow-1 px-3">
        <div className="container-lg">
          <div className="row">
           <p>Welcome to IXR-Labs Admin Section</p>
        </div>
        </div>
      </div>    
      <Footer />
      </div>
    </>
  )

}
export default Dashboard;
