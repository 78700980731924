import React, { useEffect, useState } from 'react'
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import { Link, useParams } from 'react-router-dom';
import Pagination from './Pagination';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Sidebar from '../directives/sidebar'
import {getModulesDescriptionListAction, ModulesDescriptionBlockUnBlockAction, deleteModulesDescriptionAction, getModuleDetailsAction } from '../Action/action';

const Modulesdescription = () => {
	const { id } = useParams();	
	const [ModulesDescriptionList, setModulesDescriptionListList] = useState([]);	
	const [moduledata, setmoduledata] 		= useState({});
   
   useEffect(()=>{
	    getModuleDetailsAPI();		
		getModulesDescriptionAPI();
    },[]);    
 
  const getModuleDetailsAPI = async () => {		
        let res = await getModuleDetailsAction({ 'id': id });
        if (res.success) {
            setmoduledata(res.data) 
           }
    }   
  const getModulesDescriptionAPI = async () => {
    let res = await getModulesDescriptionListAction({'module_id':id});
    if (res.success) {      
      setModulesDescriptionListList(res.data);
    } else {
		setModulesDescriptionListList([]);	
	}   
  };
  const deleteModuleDesc = async (id) => {
    Swal.fire({
		html: `
				<div>
				  <h4>Warning</h4>
				  <p>You want to Delete this module!</p>
				  <div>
					<img src="${config.baseUrl}images/warning.png" alt="Warning Image" />
				  </div>
				</div>
			  `,    
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Deleted it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteModulesDescriptionAction({ id: id });
        if (res.success) {
          getModulesDescriptionAPI();
          toast.success("Module deleted successfully!");
        } else {
		  toast.error(res.msg); 
        }
      }
    });
  };
  const handleStatus = async (id, is_active) => {
        Swal.fire({
			 html: `
				<div>
				  <h4>Warning</h4>
				  <p>${is_active === 0 ? "Are you sure you want to disable this Module?" : "Are you sure you want to enable this Module?"}</p>
				  <div>
					<img src="${config.baseUrl}images/warning.png" alt="Warning Image" />
				  </div>
				</div>
			  `,          
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: is_active == 0 ? 'Yes, Disable it!' : 'Yes, Enable it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await ModulesDescriptionBlockUnBlockAction({ 'id': id, 'is_active': is_active });
                if (res.success) {
                    getModulesDescriptionAPI();
                     if(is_active==0)
						toast.success("Module disabled successfully!");
                    else
                     toast.success("Module enabled successfully!"); 
                } else {
                   toast.error(res.msg);       
                }
            }
        })
    }
   
  
  return (
    <>
      <Sidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">        
        <Header />
         <div className="container-lg mb-4">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-0 ms-2">
              <li className="breadcrumb-item">
               <span><a className="homelink" href={`${config.baseUrl}users`} >Home</a></span>
              </li>
              <li className="breadcrumb-item active"><span>Modules</span></li>
            </ol>
          </nav>
        </div>
        <div className="body flex-grow-1 px-3">
        <div className="container-lg">
          <div className="row align-items-end">
            
          <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header">
					<div className="row align-items-center">				
						<div className="col-md-6 text-start">
						 <span>{moduledata.title} descriptions</span>
						</div>
						<div className="col-md-6 text-end">
						<a class="btn btn-primary createbtn"  href={`${config.baseUrl}modules`}>Back</a>
						&nbsp;
							<a className="btn btn-primary createbtn" href={`${config.baseUrl}addmodulesdescription/`+ id} ><i className="fa fa-plus"></i> Add description</a>
							
						</div>
					</div>					
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead className="table-light fw-semibold">
                        <tr className="align-middle table-heading">
                          <th>Sr.</th>
                          <th>Title</th>
                          <th>Status</th>
                          <th>Display Order</th>                         
                          <th className="action" style={{ minWidth: '250px' }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                         { ModulesDescriptionList.length> 0 ?
							ModulesDescriptionList.map((item, index) => (
								<>
									<tr key={index}>
										<td>{index+1}</td>
										<td>{item.title}</td>										
										<td>
											<div className="form-check form-switch">
											  <input
												className="form-check-input"
												id={`flexSwitchCheckChecked_${index}`}
												type="checkbox"
												checked={item.status === 1}
												onChange={() => handleStatus(item.id, item.status === 1 ? 0 : 1)}
											  />
											</div>
										</td>
										<td>{item.display_order}</td>
										<td>
											<Link to={`${config.baseUrl}editmodulesdescription/`+id+ '/'+ item.id}>
												<button className='btn btn-primary editbtn'><i className="far fa-edit"></i> Edit</button>
											</Link> &nbsp; <button type="button" className="btn btn-danger deletebtn" onClick={() => deleteModuleDesc(item.id)}><i className='fa fa-trash'></i> Delete</button>										  
										</td>
										
									</tr>
									</>
								)
							)
							:
							(
								<tr><td colspan="8" className="text-center">No module found.</td></tr>
							)			 
						}
                      
                      </tbody>
                    </table>                    
                  </div>
                </div>
              </div>
            </div>
          </div>          
        </div>
      </div>    
      <Footer />
      </div>
    </>
  )

}
export default Modulesdescription;
