import React, { Component, useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { LoginAction } from '../Action/action';
import Cookies from 'js-cookie'
import config from '../coreFIles/config';

const Login = () => {

	const [form, setForm] = useState({ email: '', password: '' });
	const [validationError, setvalidationError] = useState({});

	const svguser = '<use xlink:href="vendors/@coreui/icons/svg/free.svg#cil-user"></use>';
	const svglock = '<use xlink:href="vendors/@coreui/icons/svg/free.svg#cil-lock-locked"></use>';

	if (Cookies.get('loginSuccessIXRAdmin')) {
		window.location.href = `${config.baseUrl}users`;
	}

	const inputHandler = async (e) => {
		const { name, value } = e.target
		setForm((old) => {
			return { ...old, [name]: value }
		})
	}

  function validate() {
    let emailError = "";
    let passwordError = "";

    if (form.email === '') {
      emailError = "Username is required."
    }
    if (form.password === '') {
        passwordError = "Password is required."
    }
    if (emailError || passwordError) {
        setvalidationError({
          emailError, passwordError
        })
        return false
    } else {
        return true
    }
}

const SubmitForm = async (e) => {
    e.preventDefault()
    const isValid = validate();
    if (!isValid) {

    }
    else {
        let res = await LoginAction(form);
        if (res.success) {
            toast.success(res.msg);
             console.log(res.data);
            Cookies.set('loginSuccessIXRAdmin', JSON.stringify(res.data));
            setTimeout(() => {
                window.location.href = `${config.baseUrl}users`;
            }, 1200);
        } else {
            toast.error(res.msg);
        }
    }
}

  return (
  
    <>
    
    <div className="min-vh-100 d-flex flex-row align-items-center login-wraper">
    <Toaster />    
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-4 col-lg-5 col-md-7">
            <div className="card-group d-block d-md-flex row">
              <div className="card p-4 mb-0">
                <div className="card-body">
                  <h1>Login</h1>
                  <p className="text-medium-emphasis">Sign In to your account</p>
                  <div className="input-group mb-3"><span className="input-group-text">
					<svg className="icon">
						<svg dangerouslySetInnerHTML={{ __html: svguser }} />
					</svg></span>
					
					<input className={validationError.emailError ? 'form-control is-invalid' : 'form-control'} type="text" placeholder="Username" onChange={inputHandler} name="email"  />

					<span className="invalid-feedback">{validationError.emailError}</span>

                  </div>
                  <div className="input-group mb-4"><span className="input-group-text">
                      <svg className="icon">
                         <svg dangerouslySetInnerHTML={{ __html: svglock }} />
                      </svg></span>
                    <input className={validationError.passwordError ? 'form-control is-invalid' : 'form-control'} type="password" placeholder="Password"  onChange={inputHandler} name="password"  />
                    
                    <span className="invalid-feedback">{validationError.passwordError}</span>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <button className="btn btn-primary px-4" type="submit" onClick={SubmitForm}>Login</button>
                    </div>                   
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
   

    </>
  )

}
export default Login;
