import React, { useState } from "react";
import config from "../coreFIles/config";
import Cookies from "js-cookie";

const Sidebar = () => {
	
  const loginData = (!Cookies.get('loginSuccessIXRAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessIXRAdmin'));
  if (!loginData || loginData == '') {
    window.location.href = `${config.baseUrl}`;
  }
	
	
  const [pageUrl, setPageUrl] = useState(window.location.href);
  const [isToggled, setIsToggled] = useState(false);

  const logout = async () => {
    Cookies.remove("loginSuccessIXRAdmin");
    window.location.href = config.baseUrl;
  };
  const toggleClass = () => {
    setIsToggled(!isToggled);
  };
	
  return (
    <>    
    
    <div className="sidebar sidebar-dark sidebar-fixed" id="sidebar">
      <div className="sidebar-brand d-none d-md-flex logo-head">
       <img className="logo-img" src={"assets/img/logo-ixr.png"} alt="logo" width="118" height="46"  />
        
      </div>
      <ul className="sidebar-nav" data-coreui="navigation" data-simplebar="">
        {/*
			<li className="nav-item"><a className={pageUrl.match('/dashboard') ? 'active nav-link' : 'nav-link'} href={`${config.baseUrl}dashboard`}>
            <span><img src={`${config.baseUrl}images/module.png`} /></span> Dashboard</a></li>
        */}     
           <li className="nav-item"><a className={pageUrl.match('/users') ? 'active nav-link' : 'nav-link'} href={`${config.baseUrl}users`}><span><img src={`${config.baseUrl}images/user.png`} /></span> Users</a></li>
            
           <li className="nav-item"><a className={pageUrl.match('/topics') ? 'active nav-link' : 'nav-link'} href={`${config.baseUrl}topics`}>
           <span><img src={`${config.baseUrl}images/module.png`} /></span> Topics</a></li>  
           
          <li className={`nav-group ${isToggled || pageUrl.match("module") || pageUrl.match("assets") ? 'show' : ''}`}><a className="nav-link nav-group-toggle" href="javascript:;" onClick={toggleClass}><span><img src={`${config.baseUrl}images/module.png`} /></span>Modules</a>
			  <ul className="nav-group-items">           
			   <li className="nav-item"><a className={pageUrl.match("module") ? "active nav-link" : "nav-link"}href={`${config.baseUrl}modules`}><span className="nav-icon"></span>Modules</a></li> 
			   <li className="nav-item"><a className={pageUrl.match("assets") ? "active nav-link" : "nav-link"}href={`${config.baseUrl}assets-list`}><span className="nav-icon"></span>Assets</a></li> 
			  </ul>
        </li>
         
			
		<li className="nav-item"><a className={pageUrl.match("package") ? "active nav-link" : "nav-link"}href={`${config.baseUrl}packages`}><span><img src={`${config.baseUrl}images/packages.png`} /></span> Packages</a></li>
			
		<li className="nav-item"><a className={pageUrl.match("license") ? "active nav-link" : "nav-link"}href={`${config.baseUrl}licenses`}><span><img src={`${config.baseUrl}images/licence-key.png`} /></span> Licenses Key</a></li>
        
        </ul>
             {/*<button className="sidebar-toggler" type="button" data-coreui-toggle="unfoldable"></button> */}
    </div>
    
    </>
  );
};
export default Sidebar;
