import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import toast, { Toaster } from "react-hot-toast";
import config from "../coreFIles/config";
import Cookies from 'js-cookie';
import moment from 'moment';
import Swal from 'sweetalert2';
import {  getPackageDetailsAction, getPackageModulesListAction, packagemodulesdataAction, getpackagelistbranchwiseAction} from "../Action/action";
import { Link, useParams } from 'react-router-dom';
const loginData = (!Cookies.get('loginSuccessIXRAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessIXRAdmin'));


const Editpackagemodules = () => {
	const { id } = useParams();	
	const [spindetailloader, setspindetailloader] 		= useState(0);
	const [ModulesList, setModulesList] 				= useState([]); 
	const [page, setPage] 								= useState(1); 
	const [packagedata, setpackagedata] 				= useState({});	
	const [packagemodulesdata, setpackagemodulesdata] 	= useState({});	
  
   useEffect(() => {
		getPackageDetailsAPI();        
		getModulesAPI();       
		getpackagelistbranchwiseAPI();
    }, [])
   
    const getModulesAPI = async () => {
		setspindetailloader(1);
        let res = await getPackageModulesListAction({id:id});
        if (res.success) {
            setModulesList(res.data) 
			setspindetailloader(0);
           }
    } 
    const getPackageDetailsAPI = async () => {
		setspindetailloader(1);
        let res = await getPackageDetailsAction({id:id});
        if (res.success) {
			setpackagedata(res.data);
			setspindetailloader(0);
           }
    } 
    const getpackagelistbranchwiseAPI = async () => {
		setspindetailloader(1);
        let res = await getpackagelistbranchwiseAction({id:id});
        if (res.success) {
			setpackagemodulesdata(res.data);
			setspindetailloader(0);
           }
    } 
	
  return (

    <>
      <Sidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
         <Toaster />
        <Header />
         <div className="container-lg mb-4">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-0 ms-2">
              <li className="breadcrumb-item">
               <span><a className="homelink" href={`${config.baseUrl}users`} >Home</a></span>
              </li>             
              <li className="breadcrumb-item">
               <span><a href={`${config.packages}`} >Packages</a></span>
              </li>             
              <li className="breadcrumb-item active"><span>{packagedata.title}</span></li>
            </ol>
          </nav>
        </div>
        <div className="body flex-grow-1 px-3">
        <div className="container-lg">
          <div className="row align-items-end">
            
          <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header">
					<div className="row align-items-center">				
						<div className="col-md-9 text-start">
						 <span>{packagedata.title}</span>
						  <div className="mt-2 module-stats-div">
							<div>
							<span className="modules-count">{ModulesList.length}</span> 
							<span className="modules-text">Modules Attached</span>
							</div> 
						  { packagemodulesdata.length > 0 ?							  
							  packagemodulesdata.map((totalmodule, index) => (
								<>
									<div>
										<span className="modules-count">{totalmodule.module_count}</span> 
										<span className="modules-text">{totalmodule.branch_title}</span>
									</div> 
								</>
								)
							)
							: ''
							}
						  </div>
						</div>
						<div className="col-md-3 text-end">
							<a className="btn btn-primary editbtn" href={`${config.baseUrl}packages`} >Back</a> &nbsp;
							<a className="btn btn-primary createbtn" href={`${config.baseUrl}editpackagemods/`+id} ><i className="fa fa-plus"></i> Add module</a>
						</div>
					</div>					
                </div>
                <div className="card-body">          
                
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead className="table-light fw-semibold">
                        <tr className="align-middle table-heading">
                           <th>Sr.</th>                         
                          <th>Title</th>                         
                          <th>Status</th>                         
                        </tr>
                      </thead>
                      <tbody>
                         { ModulesList.length> 0 ?
							ModulesList.map((item, index) => (
								<>
									<tr key={index}>
										<td>{index+1}</td>
										<td>{item.title}</td>										
										<td>{item.is_active ==1 ? 'Active':'In-active' }</td>									<td></td>
									</tr>
									</>
								)
							)
							:
							<>
									<tr>
										<td colspan="3">No record found</td>										
									</tr>
									</>			 
						}
                      
                      </tbody>
                    </table>
                     
                  </div>
                </div>
              </div>
            </div>
          </div>          
        </div>
      </div>    
      <Footer />
      </div>
    </>
  )

};
export default Editpackagemodules;
