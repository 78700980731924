import React from 'react'

const Footer = () => {
const currentYear = new Date().getFullYear();
    return (

        <>        
        {/*
        <footer className="footer">
			<div><a href="#">IXRLABS Admin</a> ©  {currentYear}.</div> 
		 </footer>        
        */}
        </>
    )
}
export default Footer;
