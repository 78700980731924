import './App.css';
import config from "./coreFIles/config";
import Login from './component/login';
import Dashboard from "./component/dashboard";
import Roles from "./component/roles";
import Users from "./component/users";
import CreateUser from "./component/createuser";
import EditUser from "./component/edituser";
import Topics from "./component/topics";
import CreateTopic from "./component/createtopic";
import EditTopic from "./component/edittopic";
import Modules from "./component/modules";
import CreateModule from "./component/createmodule";
import EditModule from "./component/editmodule";
import Modulesdescription from "./component/modulesdescription";
import AddModulesdescription from "./component/addmodulesdescription";
import EditModulesdescription from "./component/editmodulesdescription";

import Assets from "./component/assets";
import Vrassets from "./component/vrassets";
import Webassets from "./component/webassets";
import Packages from "./component/packages";
import Addpackagemodules from "./component/addpackagemodules";
import Editpackagemodules from "./component/editpackagemodules";
import Packagemoduleslist from "./component/packagemoduleslist";
import Licenses from "./component/licenses";

import { BrowserRouter, Route, Routes } from 'react-router-dom'
function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path={`${config.baseUrl}`} element={<Login />} />
          <Route path={`${config.baseUrl}dashboard`} element={<Dashboard />} />
          <Route path={`${config.baseUrl}roles`} element={<Roles />} />
          <Route path={`${config.baseUrl}users`} element={<Users />} />
          <Route path={`${config.baseUrl}createuser`} element={<CreateUser />} />
          <Route path={`${config.baseUrl}edituser/:id`} element={<EditUser />} />
          <Route path={`${config.baseUrl}topics`} element={<Topics />} />
          <Route path={`${config.baseUrl}createtopic`} element={<CreateTopic />} />         
          <Route path={`${config.baseUrl}edittopic/:id`} element={<EditTopic />} />
          <Route path={`${config.baseUrl}modules`} element={<Modules />} />
          <Route path={`${config.baseUrl}createmodule`} element={<CreateModule />} />
          <Route path={`${config.baseUrl}editmodule/:id`} element={<EditModule />} />          
          <Route path={`${config.baseUrl}assets-list`} element={<Assets />} />         
          <Route path={`${config.baseUrl}vrassets/:id`} element={<Vrassets />} />
          <Route path={`${config.baseUrl}webassets/:id`} element={<Webassets />} />
          <Route path={`${config.baseUrl}packages`} element={<Packages />} />
          <Route path={`${config.baseUrl}licenses`} element={<Licenses />} />
          <Route path={`${config.baseUrl}addpackagemods/:id`} element={<Addpackagemodules />} />
          <Route path={`${config.baseUrl}editpackagemods/:id`} element={<Editpackagemodules />} />
          <Route path={`${config.baseUrl}packagemodlist/:id`} element={<Packagemoduleslist />} />          
          <Route path={`${config.baseUrl}modulesdescription/:id`} element={<Modulesdescription />} />
          <Route path={`${config.baseUrl}addmodulesdescription/:id`} element={<AddModulesdescription />} />
          <Route path={`${config.baseUrl}editmodulesdescription/:module_id/:id`} element={<EditModulesdescription />} />
         
         
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
