import React, { useEffect, useState } from 'react'
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import JoditEditor from "jodit-react";
import Modal from 'react-modal';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Sidebar from '../directives/sidebar'
import { Link, useParams } from 'react-router-dom';

import { getModuleDetailsAction, updateModuleDescriptionAction, getModuleDescriptionDetailsAction } from '../Action/action';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        zindex:'99999999999999999999'
    },
};


const Addmodulesdescription = () => {	
	const { module_id , id } = useParams();
    const [moduledata, setmoduledata] 			= useState({});
   	const [form, setForm] = useState({ });
	const editorHeight = '400px'; 
	useEffect(()=>{
		 getModuleDetailsAPI();		   
		 getModuleDescDetailsAPI();		   
    },[]);
	const getModuleDetailsAPI = async () => {		
        let res = await getModuleDetailsAction({ 'id': module_id });
        if (res.success) {
            setmoduledata(res.data) 
           }
    }
    
	const getModuleDescDetailsAPI = async () => {		
        let res = await getModuleDescriptionDetailsAction({ 'id': id });
        if (res.success) {
            setForm(res.data) 
           }
    }

	const [validationError, setvalidationError] = useState({});
	const inputHandler = async (e) => {
		const { name, value } = e.target
		setForm((old) => {
			return { ...old, [name]: value }
		})
	}
	const descriptionHandler = async (e) => {
		setForm((old) => {
		  return { ...old, 'description': e }
		})
	  }	  
	const handleKeyPress = (e) => {		
		const isValidInput = /^[0-9\b]+$/.test(e.key);
		if (!isValidInput) {
		  e.preventDefault();
		}
	  };  
	    
   function validate() {
		let titleError = "";
		let is_activeError = "";
		let display_orderError = "";
		let descriptionError = "";
						
		if (form.title === '') {
		  titleError = "Title is required."
		}		
		if (form.is_active === '') {
			is_activeError = "Status is required."
		}
		if (form.display_orderError === '') {
			display_orderError = "Display order is required."
		}
		
		if (form.descriptionError === '') {
			descriptionError = "Description is required."
		}
					
		if (titleError || is_activeError || display_orderError || descriptionError ) {
			setvalidationError({
			  titleError, is_activeError, display_orderError, descriptionError
			})
			return false
		} else {
			setvalidationError({
			  titleError, is_activeError, display_orderError, descriptionError
			})
			return true
		}
	}
	
   const SubmitForm = async (e) => {
		e.preventDefault()
		const isValid = validate();
		if (!isValid) {
		}
		else {
			let res = await updateModuleDescriptionAction(form);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}modulesdescription/`+module_id;
				}, 1200);
			} else {
				toast.error(res.msg);
			}
		}
	}
  	
  return (

    <>
      <Sidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <Toaster />        
        <Header />
         <div className="container-lg mb-4">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-0 ms-2">
              <li className="breadcrumb-item">
               <span><a className="homelink" href={`${config.baseUrl}users`} >Home</a></span>
              </li>
              <li className="breadcrumb-item active"><span><a href={`${config.baseUrl}modules`} >Modules</a></span></li>
              
              <li className="breadcrumb-item active"><span><a href={`${config.baseUrl}modulesdescription/`+module_id} >{moduledata.title}</a></span></li>
            </ol>
          </nav>
        </div>
        <div className="body flex-grow-1 px-3">
        <div className="container-lg">
          <div className="row">
            
          <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header">
					<div className="row align-items-center">				
						<div className="col-md-6 text-start">
						 <span>Edit Description</span>
						</div>
						<div className="col-md-6 text-end">
							<a className="btn btn-primary editbtn" href={`${config.baseUrl}modulesdescription/`+ module_id} >Back</a>
						</div>
					</div>
                </div>
                <div className="card-body inner-body">                
                 <form className="row g-3">	
					  <div className="col-md-6">
						<label className="form-label" for="first_name">Title<span className="req-star">*</span></label>
						<input className={validationError.titleError ? 'form-control is-invalid' : 'form-control'} id="title" name="title" type="text" placeholder="Enter title" onChange={inputHandler} value={form?.title}/>
						<div className="invalid-feedback">{validationError.titleError}</div>
					  </div>
					  <div className="col-md-6">
						<label className="form-label" for="is_active">Status<span className="req-star">*</span></label>
						<select className={validationError.is_activeError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="status" name="status" onChange={inputHandler} value={form?.status}>
                              <option value="">Select Status</option>								
                              <option value="1">Active</option>								
                              <option value="0">In-Active</option>								
                            </select>
                            <div className="invalid-feedback">{validationError.is_activeError}</div>
					  </div>
	  				  <div className="col-md-6">
						<label className="form-label" for="first_name">Display order<span className="req-star">*</span></label>
						<input className={validationError.display_orderError ? 'form-control is-invalid' : 'form-control'} id="display_order" name="display_order" placeholder="Enter display order" type="text" onChange={inputHandler} onKeyPress={handleKeyPress} value={form?.display_order} />	
						<div className="invalid-feedback">{validationError.display_orderError}</div>				
					  </div>	  	
					  <div className="col-md-12 mt-5">
					   <label className="form-label description-label" for="first_name">Description</label>
					   <JoditEditor
                              onChange={descriptionHandler}
                              value={form?.description}
                              name="description"
                              id="description"
                              style={{ height: editorHeight }}
                              className={validationError.descriptionError ? 'form-control is-invalid' : 'form-control'}
                            />
                            <div className="invalid-feedback">{validationError.descriptionError}</div>
                       </div>
					  <div className="col-12 text-end pt-25">
						<button className="btn btn-primary" type="submit" onClick={SubmitForm} >Update</button>
					  </div>
				  </form>                
                </div>
              </div>
            </div>
          </div>          
        </div> 
      </div>
      <Footer />
      </div>
    </>
  )

}
export default Addmodulesdescription;
