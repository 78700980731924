import React, { useEffect, useState } from 'react'
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';

import Header from '../directives/header'
import Footer from '../directives/footer'
import Sidebar from '../directives/sidebar'

import { getRoleslistAction,getBranchesListAction,getDomainsListAction,getTopicDetailsAction,updateTopicAction } from '../Action/action';

const EditTopic = () => {
	
	const [form, setForm] = useState({ id: '', title: '', branch_id: '', domain_id: '' });
	
	const [validationError, setvalidationError] = useState({});
	
	const [TopicDetails, setTopicDetails] = useState({});
	
	const [BranchesList, setBranchesList] = useState([]);
	
	const [domainsList, setdomainsList] = useState([]);

	
	const getBranchesList = async () => {
		let res = await getBranchesListAction();
		if (res.success) {
			let data = res.data;
			setBranchesList(data);	
		}
	}
	
	const getDomainsList = async () => {
		let res = await getDomainsListAction();
		if (res.success) {
			let data = res.data;
			setdomainsList(data);	
		}
	}	
	
	const getTopicDetails = async () => {
		const id = window.location.href.split("/").pop();
		let res = await getTopicDetailsAction({"id":id});
		console.log(res);
		if (res.success) {
		  setTopicDetails(res.data);
		  let data = res.data;
		  setForm((old) => {
			return { ...old, id: data.id, title: data.title, branch_id: data.branch_id, domain_id: data.domain_id};
		  });
		}
	};
	
		
	useEffect(()=>{
		getBranchesList();
        getDomainsList();
        getTopicDetails();
    },[]);
		

	const inputHandler = async (e) => {
		const { name, value } = e.target
		setForm((old) => {
			return { ...old, [name]: value }
		})
	}

   function validate() {
		let titleError = "";
		let branch_idError = "";
		let domain_idError = "";
		
		if (form.title === '') {
			titleError = "Title is required."
		}
		if (form.branch_id === '') {
			branch_idError = "Branch is required."
		}
		if (form.domain_id === '') {
			domain_idError = "Course is required."
		}
		
		
		if (titleError || branch_idError || domain_idError) {
			setvalidationError({
			  titleError, branch_idError, domain_idError
			})
			return false
		} else {
			return true
		}
	}
	
	const SubmitForm = async (e) => {
		e.preventDefault()
		const isValid = validate();
		if (!isValid) {

		}
		else {
			let res = await updateTopicAction(form);
			if (res.success) {
				toast.success(res.msg);
			} else {
				toast.error(res.msg);
			}
		}
	}
  

  return (

    <>
      <Sidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <Toaster />
        <Header />
         <div className="container-lg mb-4">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-0 ms-2">
              <li className="breadcrumb-item">
               <span><a className="homelink" href={`${config.baseUrl}users`} >Home</a></span>
              </li>
              <li className="breadcrumb-item active"><span>Edit Topic</span></li>
            </ol>
          </nav>
        </div>
        <div className="body flex-grow-1 px-3">
        <div className="container-lg">
          <div className="row">
            
          <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header">
					<div className="row align-items-center">				
						<div className="col-md-6 text-start">
						 <span>Update Topic</span>
						</div>
						<div className="col-md-6 text-end">
							<a className="btn btn-primary editbtn" href={`${config.baseUrl}topics`} >Back</a>
						</div>
					</div>
                </div>
                <div className="card-body inner-body">                
                 <form className="row g-3">	
					 					  			  
					  <div className="col-md-12">
						<label className="form-label" for="title">Title<span className="req-star">*</span></label>
						<input placeholder="Enter topic title" className={validationError.titleError ? 'form-control is-invalid' : 'form-control'} id="title" name="title" type="text" value={form.title} onChange={inputHandler} maxLength={30} />
						<div className="invalid-feedback">{validationError.titleError}</div>
					  </div>
					    <div className="col-md-6">
						<label className="form-label" for="domain_id">Course<span className="req-star">*</span></label>
						<select className={validationError.domain_idError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="domain_id" name="domain_id" value={form.domain_id} onChange={inputHandler}>
                              <option value="">Select Course</option>
								{
									domainsList.map((xdomain,index)=>{
										return(
											<option value={xdomain.id} >{xdomain.title}</option>
										)
									})
								}
                            </select>
                            <div className="invalid-feedback">{validationError.domain_idError}</div>
					  </div>
					   <div className="col-md-6">
						<label className="form-label" for="branch_id">Branch<span className="req-star">*</span></label>
						<select className={validationError.branch_idError ? 'form-control is-invalid' : 'form-control'} aria-label="select example" id="branch_id" name="branch_id"value={form.branch_id} onChange={inputHandler}>
                              <option value="">Select Branch</option>
								{
									BranchesList.map((xbranch,index)=>{
										return(
											<option value={xbranch.id} >{xbranch.title}</option>
										)
									})
								}
                            </select>
                            <div className="invalid-feedback">{validationError.branch_idError}</div>
					  </div>
					  				  
					  <div className="col-12 text-end">
						<button className="btn btn-primary" type="submit" onClick={SubmitForm} >Update</button>
					  </div>					  
				  </form>                
                </div>
              </div>
            </div>
          </div>          
        </div>
      </div>    
      <Footer />
      </div>
    </>
  )

}
export default EditTopic;
