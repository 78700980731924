import React, { Component, useEffect, useState } from 'react'
import { getAdminProfiles } from '../Action/action';
import config from '../coreFIles/config'
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie'

const Header = () => {
  const [usersList, setusersList] = useState('');
  const loginData = (!Cookies.get('loginSuccessIXRAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessIXRAdmin'));
  if (!loginData || loginData == '') {
    window.location.href = `${config.baseUrl}`;
  }

  const logout = async () => {
    Cookies.remove('loginSuccessIXRAdmin');
    window.location.href = config.baseUrl;
  }
  

 return (

    <>    
    
    <header className="header header-sticky mb-4">
        <div className="container-fluid">
          <button className="header-toggler px-md-0 me-md-3 " type="button" data-coreui-toggle="unfoldable">
			  <svg className="icon icon-lg d-none">
				<use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-menu"></use>
			  </svg>
			</button>
          
         
          <ul className="header-nav ms-3">
            <li className="nav-item dropdown"><a className="nav-link py-0" data-coreui-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                <div className="avatar avatar-md"><img className="avatar-img" src={"assets/img/avatars/2.jpg"} alt="user@email.com" /></div>
              </a>
              <div className="dropdown-menu dropdown-menu-end pt-0"> 
                <a className="dropdown-item" href="javascript:;" onClick={logout}>
                  <svg className="icon me-2">
                    <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-account-logout"></use>
                  </svg> Logout</a>
              </div>
            </li>
          </ul>
        </div>     
      </header>
      
    
    </>
  )
}
export default Header;
