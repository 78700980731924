import React, { useEffect, useState } from 'react'
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import Pagination from './Pagination';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Sidebar from '../directives/sidebar'
import {getActiveModulesListAction, getTotalActiveModulesListAction, ModuleBlockUnBlockAction, deleteModuleAction, getBranchesListAction,getDomainsListAction,getTopicListAction } from '../Action/action';

const Assets = () => {

	const [ModulesList, setModulesList] = useState([]);	  
	const [page, setPage] 				= useState(1); 
	const [totalPages, settotalPages] 				= useState(0);
	const [totalRecords, settotalRecords] 			= useState(0);
	const [pagingspinloader, setpagingspinloader] 	= useState(1);  
	const [titleFilter, SettitleFilter] = useState('');
	const [domainFilter, SetdomainFilter] = useState('');
	const [branchFilter, SetbranchFilter] = useState('');
	const [topicFilter, SettopicFilter] = useState('');
	const [BranchesList, setBranchesList] = useState([]);
	const [DomainsList, setDomainsList] = useState([]);	
	const [TopicsList, setTopicsList] = useState([]);
	const [FilterTopicsList, setFilterTopicsList] = useState([]);	
	const [statusFilter, SetstatusFilter] = useState('');	
   
   useEffect(()=>{
	    setpagingspinloader(1);
	    getBranchesListAPI();       
		getDomainsListAPI();       
		getTopicListAPI();    
		getTotalModulesListAPI();
		getModulesListAPI();
    },[]);
    
    
	const getBranchesListAPI = async () => {
		let res = await getBranchesListAction();
		if (res.success) {
			setBranchesList(res.data);	
		}
	}
	const getDomainsListAPI = async () => {
		let res = await getDomainsListAction();
		if (res.success) {
			setDomainsList(res.data);	
		}
	}
	const getTopicListAPI = async () => {
		let res = await getTopicListAction();
		if (res.success) {
			setTopicsList(res.data);	
		}
	}

   const getTotalModulesListAPI = async () => {
        let res = await getTotalActiveModulesListAction({title:titleFilter,branch:branchFilter,domain:domainFilter,topic:topicFilter,status:statusFilter});
        if (res.success) {
            settotalPages(res.data);
            settotalRecords(res.totalRecords);
             setpagingspinloader(0);
            
		}
        else
        {
			settotalPages(0);
			settotalRecords(0);		
			setpagingspinloader(0);	
		}
		
		
    }
    
  const getModulesListAPI = async () => {
    let res = await getActiveModulesListAction({title:titleFilter, branch:branchFilter, domain:domainFilter, topic:topicFilter, status:statusFilter, page:page});
    if (res.success) {      
      setModulesList(res.data);
    }
  };
  
    
  const onPageChanged = async (data)  => {
		const { currentPage, totalPages, pageLimit } = data;
		const offset = (currentPage - 1) * pageLimit;
		let res = await getActiveModulesListAction({title:titleFilter, branch:branchFilter, domain:domainFilter, topic:topicFilter, status:statusFilter, page:offset});
        if (res.success) {
            setModulesList(res.data);
        }
        else
        {
			setModulesList({});	
		}

  }	
  const deleteModule = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete this Module!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Deleted it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteModuleAction({ id: id });
        if (res.success) {
          getModulesListAPI(); 
          toast.success("Module deleted successfully!");
        } else {
           toast.error(res.msg);    
        }
      }
    });
  };
  const handleStatus = async (id, is_active) => {
        Swal.fire({
            title: 'Are you sure?',
            text: is_active == 0 ? "You want to Disable this Module!" : "You want to Enable this Module!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: is_active == 0 ? 'Yes, Disable it!' : 'Yes, Enable it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await await ModuleBlockUnBlockAction({ 'id': id, 'is_active': is_active });
                if (res.success) {
                    getModulesListAPI();
                    if(is_active==0)
						toast.success("Module disabled successfully!");
                    else
                     toast.success("Module enabled successfully!");
                } else {
                    toast.error(res.msg);       
                }
            }
        })
    }
  const SettFilter = async (e) => {
        SettitleFilter(e.target.value);        
    }
  const SetdmFilter = async (e) => {
        SetdomainFilter(e.target.value); 
         SettopicFilter('');   
        let searchDomain = e.target.value; 
		if(searchDomain != null && branchFilter !=''){
			const result = TopicsList.filter(data => String(data.branch_id).includes(branchFilter) && String(data.domain_id).includes(searchDomain)); 
			setFilterTopicsList(result); 		
		  }   
            
    }    
  const SetbrFilter = async (e) => {
        SetbranchFilter(e.target.value);
         SettopicFilter('');   
        let searchBranch = e.target.value; 
		if(searchBranch != null && domainFilter !=''){
			const result = TopicsList.filter(data => String(data.branch_id).includes(searchBranch) && String(data.domain_id).includes(domainFilter)); 
			setFilterTopicsList(result); 		
		}        
    }
  const SettopFilter = async (e) => {
        SettopicFilter(e.target.value);        
    }  	
  const SetStFilter = async (e) => {
        SetstatusFilter(e.target.value);        
    } 
     
  const FilterModules = async (e) => {
		setPage(1);	
        getTotalModulesListAPI();
        getModulesListAPI();       
    }
    
  const ClearModules = async (e) => {
	  SettitleFilter('');  
	  SetdomainFilter(''); 
	  SetbranchFilter('');       
	  SettopicFilter('');       
	  setPage(1);	
      let res = await getTotalActiveModulesListAction({title:'',branch:'',domain:'',topic:'',status:''});
        if (res.success) {
            settotalPages(res.data);
            settotalRecords(res.totalRecords);
            setpagingspinloader(0);            
		}
        else
        {
			settotalPages(0);
			settotalRecords(0);		
			setpagingspinloader(0);	
		}
      let list = await getActiveModulesListAction({title:'',branch:'',domain:'',topic:'',status:'',page:1});
		if (list.success) {
			setModulesList(list.data);	
		}
		else
		{
			setModulesList([]);	
		}      
    }
    
 
 
  return (

    <>
      <Sidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        
        <Header />
         <div className="container-lg mb-4">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-0 ms-2">
              <li className="breadcrumb-item">
               <span><a className="homelink" href={`${config.baseUrl}users`} >Home</a></span>
              </li>
              <li className="breadcrumb-item active"><span>Assets</span></li>
            </ol>
          </nav>
        </div>
        <div className="body flex-grow-1 px-3">
        <div className="container-lg">
          <div className="row">
            
          <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header">
					<div className="row align-items-center">				
						<div className="col-md-6 text-start">
						 <span>Search Module</span>
						</div>
						<div className="col-md-6 text-end">
							
						</div>
					</div>
					<div className="row mb-4 mt-4 align-items-end">						
						<div className="col-lg-4  col-xl-3 col-md-6 mt-3">	
						<label className="form-label">Module Name</label>						 
						  <input type="text" name="title" placeholder="Module Title..." id="input-name" data-oc-target="autocomplete-name" className="form-control" autocomplete="off" onChange={SettFilter} value={titleFilter}/>
						</div>
						<div className="col-lg-4 col-xl-2 col-md-6 mt-3">	
						<label className="form-label">Course</label>							 
							<select className="form-control" id="domain_id" name="domain_id" onChange={SetdmFilter} value={domainFilter}>
									  <option value="">Select Course</option>								
									 {
									DomainsList.map((item,index)=>{
										return(
											<option value={item.id}>{item.title}</option>
										)
									})
								}							
									</select>
						</div>
						<div className="col-lg-4 col-xl-2 col-md-6 mt-3">	
						<label className="form-label">Branch</label>							 
							<select className="form-control" id="branch_id" name="branch_id" onChange={SetbrFilter} value={branchFilter}>
								  <option value="">Select Branch</option>
								  {
									BranchesList.map((item)=>{
										return(
											<option value={item.id}>{item.title}</option>
										)
									})
									}	
									</select>
						</div>
						<div className="col-lg-4 col-xl-2 col-md-6 mt-3">	
						<label className="form-label">Topic</label>							 
							<select className="form-control" id="topic_id" name="topic_id" onChange={SettopFilter} value={topicFilter}>
									  <option value="">Select Topic</option>					
										{
									FilterTopicsList.map((item,index)=>{
										return(
											<option value={item.id}>{item.title}</option>
										)
									})
								}						
									</select>
						</div>						
						<div className="col-lg-4 col-xl-3 col-md-12 mt-3">
						  <button type="button" id="button-filter" className="btn btn-info" onClick={() => FilterModules()}><i className="fa fa-search"></i> Search</button>
						  &nbsp;
						  <button type="button" id="button-filter" className="btn btn-primary" onClick={() => ClearModules()}>Clear</button>
						</div>
					</div>	
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead className="table-light fw-semibold">
                        <tr className="align-middle table-heading">
                           <th>Sr.</th>
                          <th>Module</th>
                          <th>Topic</th>
                          <th>Branch</th>
                          <th>Course</th>
                          <th>Version</th>
                          <th style={{ minWidth: '120px' }}>Created By</th>
                          <th classname="action" style={{ minWidth: '335px' }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                         { ModulesList.length> 0 ?
							ModulesList.map((item, index) => (
								<>
									<tr key={index}>
										<td>{index+1}</td>
										<td>{item.title}</td>
										<td>{item.topic_title}</td>
										<td>{item.branch_title}</td>
										<td>{item.domain_title}</td>										
										<td>{item.version}</td>
										<td>{item.first_name}</td>										
										<td>
											<Link to={`${config.baseUrl}vrassets/` + item.id} className='btn btn-info createbtn'>
												<i className="fa-solid fa-plus"></i> Add VR Asset
											</Link> &nbsp;											
											<Link className='btn btn-info createbtn' to={`${config.baseUrl}webassets/` + item.id}>
												<i className="fa-solid fa-plus"></i> Add WebGL
											</Link>									  
										</td>
										
									</tr>
									</>
								)
							)
							:
							(
								<tr><td colspan="8" className="text-center">No module found.</td></tr>
							)					 
						}
                      
                      </tbody>
                    </table>
                     {totalPages > 0 ?										  
						 <Pagination totalRecords={totalRecords} totalPages={totalPages} pageNeighbours={page} onPageChanged={onPageChanged}   />
						 : ''
					 }	
                  </div>
                </div>
              </div>
            </div>
          </div>          
        </div>
      </div>    
      <Footer />
      </div>
    </>
  )

}
export default Assets;
