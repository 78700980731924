import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import toast, { Toaster } from "react-hot-toast";
import config from "../coreFIles/config";
import Cookies from 'js-cookie';
import moment from 'moment';
import Swal from 'sweetalert2';
import { getBranchesListAction, getDomainsListAction, getTopicListAction, getModulesListAction,createPackageModulesAction} from "../Action/action";
import { Link, useParams } from 'react-router-dom';
const loginData = (!Cookies.get('loginSuccessIXRAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessIXRAdmin'));


const Addpackagemodules = () => {
	const { id } = useParams();
	const [BranchesList, setBranchesList] = useState([]);	
	const [DomainsList, setDomainsList] = useState([]);
	const [TopicsList, setTopicsList] = useState([]);
	const [spindetailloader, setspindetailloader] = useState(0);
	const [ModulesList, setModulesList] = useState([]);	
	const [selectAll, setSelectAll] = useState(false);
    const [selectedModules, setSelectedModules] = useState([]);	
    const [titleFilter, SettitleFilter] = useState('');
	const [domainFilter, SetdomainFilter] = useState('');
	const [branchFilter, SetbranchFilter] = useState('');
	const [topicFilter, SettopicFilter] = useState('');	
	const [FilterTopicsList, setFilterTopicsList] = useState([]);	
	const [statusFilter, SetstatusFilter] = useState('');	
	const [page, setPage] 				= useState(1); 
	const editorHeight = '400px'; 
	const [packagedata, setpackagedata] = useState({ 
		package_id: id, 	
		modules: ''		
	 });	
  
   useEffect(() => {	   
	    getBranchesListAPI();       
		getDomainsListAPI();       
		getTopicListAPI();	
		getModulesAPI();        
    }, [])
   
    const getModulesAPI = async () => {
		setspindetailloader(1);
        let res = await getModulesListAction({title:titleFilter, branch:branchFilter, domain:domainFilter, topic:topicFilter, status:statusFilter, page:page});
        if (res.success) {
            setModulesList(res.data) 
			setspindetailloader(0);
           }
    } 
	const getBranchesListAPI = async () => {
		let res = await getBranchesListAction();
		if (res.success) {
			setBranchesList(res.data);	
		}
	}
	const getDomainsListAPI = async () => {
		let res = await getDomainsListAction();
		if (res.success) {
			setDomainsList(res.data);	
		}
	}
	const getTopicListAPI = async () => {
		let res = await getTopicListAction();
		if (res.success) {
			setTopicsList(res.data);	
		}
	}
	const SettFilter = async (e) => {
        SettitleFilter(e.target.value);        
    }
    const SetdmFilter = async (e) => {
        SetdomainFilter(e.target.value); 
        let searchDomain = e.target.value; 
		if(searchDomain != null && branchFilter !=''){
			const result = TopicsList.filter(data => String(data.branch_id).includes(branchFilter) && String(data.domain_id).includes(searchDomain)); 
			setFilterTopicsList(result); 		
		  }   
            
    }    
    const SetbrFilter = async (e) => {
        SetbranchFilter(e.target.value);
        let searchBranch = e.target.value; 
		if(searchBranch != null && domainFilter !=''){
			const result = TopicsList.filter(data => String(data.branch_id).includes(searchBranch) && String(data.domain_id).includes(domainFilter)); 
			setFilterTopicsList(result); 		
		}        
    }
    const SettopFilter = async (e) => {
        SettopicFilter(e.target.value);        
    }  
	function validate() {
		let modulesError = "";
		packagedata.modules = selectedModules;			
		if (selectedModules.length === 0) {
		  modulesError = "Modules are required.";
		}
				
		if (modulesError) {
			toast.error(modulesError);
			return false
		} else {
			return true
		}
	}
	const SubmitForm = async (e) => {
		e.preventDefault()
		const isValid = validate();
		if (!isValid) {

		}
		else {
			let res = await createPackageModulesAction(packagedata);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}packages`;
				}, 1200);
			} else {
				toast.error(res.msg);
			}
		}
	}
	const handleCheckboxChange = (rowId) => {
    const isSelected = selectedModules.includes(rowId);
    let newSelectedModules;

    if (isSelected) {
      newSelectedModules = selectedModules.filter((id) => id !== rowId);
    } else {
      newSelectedModules = [...selectedModules, rowId];
    }

    setSelectedModules(newSelectedModules);
  };
	const handleSelectAll = () => {
		if (selectAll) {
		  setSelectedModules([]);
		} else {
		  const allRowIds = ModulesList.map((row) => row.id);
		  setSelectedModules(allRowIds);
		}

		setSelectAll(!selectAll);
	  };
	const FilterModules = async (e) => {
		getModulesAPI();       
    }  
     const onPageChanged = async (data)  => {
		const { currentPage, totalPages, pageLimit } = data;
		const offset = (currentPage - 1) * pageLimit;
		let res = await getModulesListAction({title:titleFilter, branch:branchFilter, domain:domainFilter, topic:topicFilter, status:statusFilter, page:offset});
        if (res.success) {
            setModulesList(res.data);
        }
        else
        {
			setModulesList({});	
		}

  }	
	const ClearModules = async (e) => {
	  SettitleFilter('');  
	  SetdomainFilter(''); 
	  SetbranchFilter('');       
	  SettopicFilter('');
	   setPage(1);	
	  let res = await getModulesListAction({title:'', branch:'', domain:'', topic:'', status:''});
      if (res.success) {
            setModulesList(res.data)          
            setspindetailloader(0);
           }
            
    }
    
 
  return (

    <>
      <Sidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
         <Toaster />
        <Header />
         <div className="container-lg mb-4">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-0 ms-2">
              <li className="breadcrumb-item">
               <span><a href={`${config.baseUrl}users`} >Home</a></span>
              </li>             
              <li className="breadcrumb-item active"><span>Modules</span></li>
            </ol>
          </nav>
        </div>
        <div className="body flex-grow-1 px-3">
        <div className="container-lg">
          <div className="row">
            
          <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header">
					<div className="row align-items-center">				
						<div className="col-md-6 text-start">
						 <span>Search modules</span>
						</div>
						<div className="col-md-6 text-end">
							<a className="btn btn-primary editbtn" href={`${config.baseUrl}packages`} >Back</a>
						</div>
					</div>
					<div className="row mb-4 mt-4 align-items-end">
						<div className="col-md-3">	
						<label className="form-label">Module Name</label>						 
					  <input type="text" name="title" placeholder="Module Title..." id="input-name" data-oc-target="autocomplete-name" className="form-control" autocomplete="off" onChange={SettFilter} value={titleFilter}/>
					</div>
					<div className="col-md-2">	
					<label className="form-label">Course</label>						 
						<select className="form-control" id="domain_id" name="domain_id" onChange={SetdmFilter} value={domainFilter}>
								  <option value="">Select Course</option>								
								 {
								DomainsList.map((item,index)=>{
									return(
										<option value={item.id}>{item.title}</option>
									)
								})
							}							
						</select>
					</div>
					<div className="col-md-2">	
					<label className="form-label">Branch</label>						 
							<select className="form-control" id="branch_id" name="branch_id" onChange={SetbrFilter} value={branchFilter}>
								  <option value="">Select Branch</option>
								  {
									BranchesList.map((item)=>{
										return(
											<option value={item.id}>{item.title}</option>
										)
									})
									}	
									</select>
						</div>
						<div className="col-md-2">
						<label className="form-label">Topic</label>							 
							<select className="form-control" id="topic_id" name="topic_id" onChange={SettopFilter} value={topicFilter}>
									  <option value="">Select Topic</option>					
										{
									FilterTopicsList.map((item,index)=>{
										return(
											<option value={item.id}>{item.title}</option>
										)
									})
								}						
									</select>
						</div>
						<div className="col-md-3">
						  <button type="button" id="button-filter" className="btn btn-info" onClick={() => FilterModules()}><i className="fa fa-search"></i> Search</button>
						  &nbsp;&nbsp;&nbsp;
						  <button type="button" id="button-filter" className="btn btn-primary" onClick={() => ClearModules()}>Clear</button>
						</div>
										 				
										 				
									
					</div>	
                </div>
                <div className="card-body">          
                
                  <div className="table-responsive package-module-list">
                    <table className="table mb-0">
                      <thead className="table-light fw-semibold">
                        <tr className="align-middle table-heading">
                           <th>Sr.</th>                         
                          <th>Title</th>                         
                          <th>Status</th>                          
                          <th  className="text-right">Select All &nbsp;  <input
							type="checkbox"
							checked={selectAll}
							onChange={handleSelectAll}
						  />
						  </th>
                        </tr>
                      </thead>
                      <tbody>
                         { ModulesList.length> 0 ?
							ModulesList.map((item, index) => (
								<>
									<tr key={index} className={selectedModules.includes(item.id)? 'slectedmodule':'' }>
										<td>{index+1}</td>
										<td>{item.title}</td>										
										<td>{item.is_active ==1 ? 'Active':'In-active' }</td>
										<td className="text-right">										
											 <input
												  type="checkbox"
												  checked={selectedModules.includes(item.id)}
												  onChange={() => handleCheckboxChange(item.id)}
												/>						  
										</td>
										
									</tr>
									</>
								)
							)
							:''				 
						}
                      
                      </tbody>
                    </table>
                     
                  </div>
                  <div className="row mt-4">
						 <div className="col-md-12 text-end pt-25">
							<button className="btn btn-primary" type="button" onClick={SubmitForm}>Save</button>
						 </div>
                     </div>
                </div>
              </div>
            </div>
          </div>          
        </div>
      </div>    
      <Footer />
      </div>
    </>
  )

};
export default Addpackagemodules;
